import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import { Box, Chip, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Button, Input, Select } from "antd";
import { useAtomValue } from "jotai";
import { useRecoilValue } from "recoil";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import ADMIN_USER_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_USER_QUERY";
import { FORWARDING_INVOICE_ATOMS } from "@sellernote/_shared/src/states/forwarding/invoice";
import { SellernoteAppRegion } from "@sellernote/_shared/src/types/common/common";
import { ForwardingAdminUserListItem } from "@sellernote/_shared/src/types/forwarding/adminUser";
import {
  FetchTrelloListParams,
  TrelloBidList,
} from "@sellernote/_shared/src/types/forwarding/trello";
import {
  checkIfETDExpiresWhenMoving,
  checkIfETDExpiresWhenScheduling,
  checkIsInlandFarePaymentRequest,
} from "@sellernote/_shared/src/utils/forwarding/trello";

import koreaFlag from "../../../../images/regionFlag/korea.webp";
import singapore from "../../../../images/regionFlag/singapore.webp";
import { FORWARDING_ADMIN_AUTH_SELECTORS } from "../../../../jotaiStates/auth";
import CheckIsPaymentModal from "./CheckPaymentModal";
import ScheduleConfirmModal from "./ScheduleConfirmModal";
import TrelloCardList from "./TrelloCardList";
import TrelloDetail from "./TrelloDetail";
import useExportCardListData from "./useExportCardListData";
import useImportCardListData from "./useImportCardListData";

const { Option } = Select;

const TrelloList = ({
  trelloListData,
  adminData,
  fetchTrelloListParams,
  setIsAutoFetch,
  isAutoFetch,
  setFetchTrelloListParams,
}: {
  trelloListData: TrelloBidList[];
  adminData: ForwardingAdminUserListItem[];
  fetchTrelloListParams: FetchTrelloListParams;
  setIsAutoFetch: React.Dispatch<React.SetStateAction<boolean>>;
  isAutoFetch: boolean;
  setFetchTrelloListParams: (value: FetchTrelloListParams) => void;
}) => {
  const trelloCardId = useRecoilValue(
    FORWARDING_INVOICE_ATOMS.ADMIN_TRELLO_CARD_ID
  );

  const { data: { name: adminName } = {} } =
    ADMIN_USER_QUERY.useGetAdminUserDetail({
      adminId: fetchTrelloListParams.adminId ?? 0,
      enabled: Boolean(fetchTrelloListParams.adminId),
    });

  const history = useHistory();

  const { serviceType }: { serviceType: string } = useParams();

  const currentAdminAuthInfo = useAtomValue(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_FORWARDING_ADMIN_AUTH_INFO
  );

  const [showModal, setShowModal] = useState(false);
  const [bidId, setBidId] = useState(0);
  const [showScheduleConfirmModal, setShowScheduleConfirmModal] =
    useState(false);
  const [showCheckIsPaymentModal, setShowCheckIsPaymentModal] = useState(false);
  const [cardEndAddress, setCardEndAddress] = useState<null | string>(null);

  const openTrelloCardDetail = useCallback((bidId: number) => {
    setBidId(bidId);
    setShowModal(true);
  }, []);

  useEffect(() => {
    if (trelloCardId) {
      openTrelloCardDetail(trelloCardId);
    }
  }, [history.action, openTrelloCardDetail, trelloCardId]);

  const onClickSearch = useCallback(() => {
    setIsAutoFetch(true);
  }, [setIsAutoFetch]);

  const handleChangeRegionFilter = useCallback(
    (region: SellernoteAppRegion | "all") => {
      setFetchTrelloListParams({ ...fetchTrelloListParams, region });
    },
    [fetchTrelloListParams, setFetchTrelloListParams]
  );

  const handleChangeManagerFilter = useCallback(
    (e: number) => {
      setFetchTrelloListParams({ ...fetchTrelloListParams, adminId: e });
    },
    [fetchTrelloListParams, setFetchTrelloListParams]
  );

  const handleChangeCompanyFilter = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (isAutoFetch) {
        setIsAutoFetch(false);
      }
      setFetchTrelloListParams({
        ...fetchTrelloListParams,
        company: e.target.value,
      });
    },
    [
      fetchTrelloListParams,
      isAutoFetch,
      setFetchTrelloListParams,
      setIsAutoFetch,
    ]
  );

  const handleChangeBidIdFilter = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (isAutoFetch) {
        setIsAutoFetch(false);
      }

      setFetchTrelloListParams({
        ...fetchTrelloListParams,
        bidId: Number(e.target.value) ? Number(e.target.value) : undefined,
      });
    },
    [
      fetchTrelloListParams,
      isAutoFetch,
      setFetchTrelloListParams,
      setIsAutoFetch,
    ]
  );

  const handleChangeBLFilter = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (isAutoFetch) {
        setIsAutoFetch(false);
      }
      setFetchTrelloListParams({
        ...fetchTrelloListParams,
        BL: e.target.value,
      });
    },
    [
      fetchTrelloListParams,
      isAutoFetch,
      setFetchTrelloListParams,
      setIsAutoFetch,
    ]
  );

  const handleTrelloCardClick = useCallback(
    (cardData: TrelloBidList) => {
      if (
        APP_NAME === "partner-admin" ||
        currentAdminAuthInfo?.authority === "finance" ||
        serviceType === "export"
      ) {
        openTrelloCardDetail(cardData.id);
        return;
      }

      if (
        checkIfETDExpiresWhenScheduling(cardData) ||
        checkIfETDExpiresWhenMoving(cardData)
      ) {
        setShowScheduleConfirmModal(true);
        setBidId(cardData.id);
        return;
      }

      if (checkIsInlandFarePaymentRequest(cardData)) {
        setShowCheckIsPaymentModal(true);
        setBidId(cardData.id);
        setCardEndAddress(cardData.endAddress);
        return;
      }

      openTrelloCardDetail(cardData.id);
    },
    [currentAdminAuthInfo?.authority, openTrelloCardDetail, serviceType]
  );

  const getTrelloCardCustomsChip = useCallback((cardData: TrelloBidList) => {
    if (cardData.management.customsStatus === "inProgress") {
      return (
        <Chip component="div" size="small" label={"통관 중"} color={"info"} />
      );
    }
    if (cardData.management.customsStatus === "reviewDocuments") {
      return (
        <Chip
          component="div"
          size="small"
          label={"서류검토 요청"}
          color={"success"}
        />
      );
    }
    if (cardData.management.customsStatus === "quarantine") {
      return (
        <Chip
          component="div"
          size="small"
          label={"검역 진행 중"}
          color={"warning"}
        />
      );
    }
    if (cardData.management.customsStatus === "selectivity") {
      return (
        <Chip
          component="div"
          size="small"
          label={"검사 선별"}
          color={"error"}
        />
      );
    }
  }, []);

  const getTrelloCardCommonBody = useCallback(
    (trelloListData: TrelloBidList) => {
      return (
        <>
          <Typography sx={{ fontSize: 12 }} variant="body2">
            {trelloListData.management.memo}
          </Typography>

          <Typography
            variant="body2"
            component={"div"}
            sx={{ fontWeight: "bold", color: grey[700] }}
          >
            {trelloListData.id}{" "}
            {trelloListData?.poNumber &&
              trelloListData.poNumber.length > 0 &&
              `${
                trelloListData.poNumber.length > 1
                  ? `/ ${trelloListData.poNumber[0]} 외 ${trelloListData.poNumber.length}`
                  : `/ ${trelloListData.poNumber[0]}`
              }`}
          </Typography>

          {trelloListData.management.ETA && trelloListData.management.ETD && (
            <Typography
              variant="body2"
              sx={{ fontWeight: "bold", color: grey[700] }}
            >
              {trelloListData.management.ETD} ~ {trelloListData.management.ETA}
            </Typography>
          )}
        </>
      );
    },
    []
  );

  const getTrelloCardTitle = useCallback((cardData: TrelloBidList) => {
    return (
      <Box>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{
              fontSize: 14,
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              fontWeight: "bold",
            }}
          >
            {cardData.team.company ?? "-"}
          </Typography>

          <img
            src={cardData.region === "KR" ? koreaFlag : singapore}
            alt={cardData.region === "KR" ? "KR" : "SG"}
            style={{ width: "22px", height: "15px" }}
          />
        </div>

        <Typography
          sx={{
            fontSize: 12,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          ({cardData.team.name ?? "-"})
        </Typography>
      </Box>
    );
  }, []);

  const { importCardListData } = useImportCardListData({
    trelloListData,
    getTrelloCardTitle,
    getTrelloCardCommonBody,
    getTrelloCardCustomsChip,
    handleTrelloCardClick,
  });

  const { exportCardListData } = useExportCardListData({
    trelloListData,
    getTrelloCardTitle,
    getTrelloCardCommonBody,
    handleTrelloCardClick,
  });

  const salesName = useMemo(() => {
    return (
      adminData &&
      adminData.filter((v: ForwardingAdminUserListItem) => {
        return v.isForwardingManager === true;
      })
    );
  }, [adminData]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "#fff",
        }}
      >
        {APP_NAME === "shipda-admin" && (
          <Select
            style={{
              width: "100%",
              maxWidth: 224,
              fontWeight: "normal",
              marginLeft: 16,
            }}
            value={fetchTrelloListParams.region}
            onSelect={(region: SellernoteAppRegion | "all") => {
              handleChangeRegionFilter(region);
            }}
          >
            <Option value={"all"}>국가</Option>
            <Option value={"KR"}>한국</Option>
            <Option value={"SG"}>싱가포르</Option>
          </Select>
        )}

        <Select
          style={{
            width: "100%",
            maxWidth: 224,
            fontWeight: "normal",
            marginLeft: 16,
          }}
          value={adminName ?? "전체"}
          onSelect={(e: string | number) => {
            if (typeof e === "string") return;

            handleChangeManagerFilter(e);
          }}
        >
          <Option value={undefined}>전체</Option>
          {salesName.map((v: ForwardingAdminUserListItem) => {
            return (
              <Option key={v.id} value={v.id}>
                {v.name}
              </Option>
            );
          })}
        </Select>

        <Input
          value={fetchTrelloListParams.company}
          onChange={handleChangeCompanyFilter}
          style={{ width: 200, marginLeft: 16 }}
          placeholder="회사명"
          onPressEnter={onClickSearch}
          allowClear
        ></Input>
        <Button onClick={onClickSearch}>
          <SearchOutlined />
        </Button>

        <Input
          onChange={handleChangeBidIdFilter}
          value={fetchTrelloListParams.bidId}
          style={{ width: 200, marginLeft: 16 }}
          placeholder="의뢰번호 입력"
          onPressEnter={onClickSearch}
          allowClear
        ></Input>

        <Button onClick={onClickSearch}>
          <SearchOutlined />
        </Button>

        <Input
          onChange={handleChangeBLFilter}
          value={fetchTrelloListParams.BL}
          style={{ width: 200, marginLeft: 16 }}
          placeholder="BL번호 입력"
          onPressEnter={onClickSearch}
          allowClear
        ></Input>

        <Button onClick={onClickSearch}>
          <SearchOutlined />
        </Button>
      </div>

      <TrelloCardList
        cardListData={
          serviceType === "export" ? exportCardListData : importCardListData
        }
      />

      {showModal && (
        <TrelloDetail
          showModal={showModal}
          setShowModal={setShowModal}
          bidId={bidId}
          trelloListData={trelloListData}
          fetchTrelloListParams={fetchTrelloListParams}
          isExport={serviceType === "export"}
        />
      )}

      {showScheduleConfirmModal && (
        <ScheduleConfirmModal
          onTrelloDetailOpen={openTrelloCardDetail}
          showScheduleConfirmModal={showScheduleConfirmModal}
          setShowScheduleConfirmModal={setShowScheduleConfirmModal}
          bidId={bidId}
        />
      )}

      {showCheckIsPaymentModal && (
        <CheckIsPaymentModal
          onTrelloDetailOpen={openTrelloCardDetail}
          showCheckIsPaymentModal={showCheckIsPaymentModal}
          setShowCheckIsPaymentModal={setShowCheckIsPaymentModal}
          bidId={bidId}
          endAddress={cardEndAddress}
        ></CheckIsPaymentModal>
      )}
    </div>
  );
};

export default TrelloList;
