import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";

import { GET_ADMIN_BID_LIST_FILTER } from "@sellernote/_shared/src/api-interfaces/shipda-api/adminBid";
import { SellernoteAppRegion } from "@sellernote/_shared/src/types/common/common";
import { BidSearchType } from "@sellernote/_shared/src/types/forwarding/adminBid";
import {
  BidProjectStatus,
  BidServiceType,
  BidStatus,
} from "@sellernote/_shared/src/types/forwarding/bid";
import { FilterDataKey } from "@sellernote/_shared/src/types/forwarding/common";
import {
  BID_LIST_SEARCH_TYPE_OPTION_LIST,
  BID_PROJECT_STATUS_OPTION_LIST,
  BID_SERVICE_TYPE_OPTION_LIST,
  BID_STATUS_OPTION_LIST,
} from "@sellernote/_shared/src/utils/common/options";
import { TermSearchType } from "@sellernote/_shared-for-admin/src/hooks/common/useSearchWithTerm";

import useSearchWithDateWithNoUseEffect, {
  SearchWithDateTypeOption,
} from "../../../../hooks/useSearchWithDateWithNoUseEffect";
import useSearchWithTermWithHistorySave from "../../../../hooks/useSearchWithTermWithHistorySave";

import { SHARED_FOR_FORWARDING_ADMIN_ATOMS } from "../../../../states/filters";
import useMuiSelect from "./useMuiSelect";

const useBidTablePanel = (filterDataKey: FilterDataKey) => {
  const filterData = useRecoilValue(
    SHARED_FOR_FORWARDING_ADMIN_ATOMS[filterDataKey]
  );

  const history = useHistory();

  const [region, setRegion] = useRecoilState(
    SHARED_FOR_FORWARDING_ADMIN_ATOMS.ADMIN_REGION_FILTER
  );

  const termSearchTypeOptions: TermSearchType<BidSearchType>[] =
    BID_LIST_SEARCH_TYPE_OPTION_LIST;

  const searchTermHistory = useMemo(() => {
    const termSearchTypeArray = termSearchTypeOptions.map((item) => item.value);

    const itemKey = termSearchTypeArray.find(
      (item) => filterData[item] && item
    );

    const itemKeyIndex = termSearchTypeArray.findIndex(
      (item) => filterData[item] && filterData[item]
    );

    if (itemKey && itemKeyIndex && filterData && filterData[itemKey])
      return {
        itemKey,
        itemKeyIndex,
      };
  }, [filterData, termSearchTypeOptions]);

  const historyDataCallback = useCallback(
    (itemKey: keyof GET_ADMIN_BID_LIST_FILTER = "company") => {
      return filterData[itemKey] && filterData[itemKey];
    },
    [filterData]
  );

  const { TermSearchPanel, reset, debouncedSearchTermWithObject } =
    useSearchWithTermWithHistorySave({
      termSearchTypeOptions,
      history: searchTermHistory,
      historyData: searchTermHistory
        ? historyDataCallback(searchTermHistory?.itemKey)
        : historyDataCallback(),
    });

  const dateSearchTypeOptions: SearchWithDateTypeOption<"createdAt">[] = [
    {
      label: "생성일",
      value: "createdAt",
    },
  ];

  const {
    DateSearchPanel,
    dateSearchType,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
  } = useSearchWithDateWithNoUseEffect({
    dateSearchTypeOptions,
    dateHistory: {
      startDate: filterData?.fromDate,
      endDate: filterData?.toDate,
      history: {
        itemKey: "createdAt",
        itemKeyIndex: 0,
      },
    },
  });

  const {
    selectedValue: status,
    setSelectedValue: setStatus,
    MuiSelect: statusSelect,
  } = useMuiSelect({
    options: BID_STATUS_OPTION_LIST,
    title: "전체 상태",
    minWidth: 120,
    defaultValue: filterData.status ?? "all",
  });

  const {
    selectedValue: projectStatus,
    setSelectedValue: setProjectStatus,
    MuiSelect: projectStatusSelect,
  } = useMuiSelect({
    options: BID_PROJECT_STATUS_OPTION_LIST,
    title: "전체 세부 상태",
    minWidth: 120,
    defaultValue: filterData.projectStatus ?? "all",
  });

  const {
    selectedValue: serviceType,
    setSelectedValue: setServiceType,
    MuiSelect: serviceTypeSelect,
  } = useMuiSelect({
    options: BID_SERVICE_TYPE_OPTION_LIST,
    title: "서비스 전체",
    minWidth: 120,
    defaultValue: filterData.serviceType ?? "all",
  });

  const dateFilter = useMemo(() => {
    if (startDate && endDate) {
      return { fromDate: startDate, toDate: endDate };
    }
    return null;
  }, [endDate, startDate]);

  const objectServiceTypeKey = useMemo(() => {
    if (serviceType === "all") return;
    return {
      serviceType: serviceType as BidServiceType,
    };
  }, [serviceType]);

  const objectStatusKey = useMemo(() => {
    if (status === "all") return;
    return { status: status as BidStatus };
  }, [status]);

  const objectProjectStatusKey = useMemo(() => {
    if (projectStatus === "all") return;
    return { projectStatus: projectStatus as BidProjectStatus };
  }, [projectStatus]);

  const debouncedSearchTermVerifiedByNumberOfCharacters = useMemo(() => {
    if (!debouncedSearchTermWithObject) {
      return undefined;
    }

    if ("BL" in debouncedSearchTermWithObject) {
      if (debouncedSearchTermWithObject["BL"].length >= 5) {
        return debouncedSearchTermWithObject;
      }
      return undefined;
    }

    return debouncedSearchTermWithObject;
  }, [debouncedSearchTermWithObject]);

  const handleReset = () => {
    setStartDate("");
    setEndDate("");
    reset(event);
    setStatus("all");
    setProjectStatus("all");
    setServiceType("all");
  };

  const TablePanel = (
    <Grid key={"filterGrid"} direction="column" spacing={2} container>
      <Grid justifyContent={"flex-end"} spacing={3} container direction={"row"}>
        <FormControl sx={{ minWidth: 120 }} size="small">
          <InputLabel>서비스 지역</InputLabel>

          <Select
            value={region}
            onChange={(e) => {
              setRegion(e.target.value as SellernoteAppRegion);
              history.push(`/bid/${e.target.value}`);
            }}
            size={"small"}
          >
            <MenuItem value="KR">한국</MenuItem>
            <MenuItem value="SG">싱가포르</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid
        item
        container
        direction={"row"}
        justifyContent={"flex-end"}
        spacing={0.5}
      >
        <Grid item>
          <Button
            variant="outlined"
            onClick={handleReset}
            sx={{ marginTop: 0.3 }}
          >
            초기화
          </Button>
        </Grid>

        <Grid item>{statusSelect}</Grid>

        <Grid item>{projectStatusSelect}</Grid>

        <Grid item>{serviceTypeSelect}</Grid>
      </Grid>

      <Box sx={{ marginBottom: "3px", marginLeft: "22px" }}>
        <DateSearchPanel />
        {TermSearchPanel}
      </Box>
    </Grid>
  );
  return {
    debouncedSearchTermWithObject:
      debouncedSearchTermVerifiedByNumberOfCharacters,
    dateFilter,
    objectServiceTypeKey,
    objectStatusKey,
    objectProjectStatusKey,
    TablePanel,
  };
};

export default useBidTablePanel;
