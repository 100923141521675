import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { Button, Card, Grid, Typography } from "@mui/material";

import SHIP_QUERY from "@sellernote/_shared/src/queries/forwarding/SHIP_QUERY";
import TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import Modal from "@sellernote/_shared-for-admin/src/components/Modal";

import EditShipScheduleModal from "../TrelloDetail/RegisterShipSchedule/EditShipScheduleModal";

const ScheduleConfirmModal = ({
  setShowScheduleConfirmModal,
  showScheduleConfirmModal,
  bidId,
  onTrelloDetailOpen,
}: {
  setShowScheduleConfirmModal: Dispatch<SetStateAction<boolean>>;
  showScheduleConfirmModal: boolean;
  bidId: number;
  onTrelloDetailOpen: (cardId: number) => void;
}) => {
  const { data: trelloDetailData } = TRELLO_BID_QUERY.useGetTrelloBidDetail({
    bidId,
  });

  const { data: shipList } = SHIP_QUERY.useGetShipList();

  const { mutate: confirmShipSchedule } = SHIP_QUERY.useConfirmShipSchedule(
    bidId,
    {
      failureModalInfo: {
        customizeMessage: () => ({
          title: "에러가 있습니다. 개발자에게 문의해주세요",
        }),
      },
    }
  );

  const [showsEditShipScheduleModal, setShowsEditShipScheduleModal] =
    useState(false);

  const handleModalCancel = useCallback(() => {
    setShowScheduleConfirmModal(false);
  }, [setShowScheduleConfirmModal]);

  const handleTrelloModalOpenClick = useCallback(() => {
    setShowScheduleConfirmModal(false);
    onTrelloDetailOpen(bidId);
  }, [bidId, onTrelloDetailOpen, setShowScheduleConfirmModal]);

  const handleEditShipScheduleModalClick = useCallback(() => {
    setShowsEditShipScheduleModal(true);
  }, []);

  const handleShipScheduleConfirmClick = useCallback(() => {
    confirmShipSchedule(
      {},
      {
        onSuccess: () => {
          setShowScheduleConfirmModal(false);
          onTrelloDetailOpen(bidId);
        },
      }
    );
  }, [
    bidId,
    confirmShipSchedule,
    onTrelloDetailOpen,
    setShowScheduleConfirmModal,
  ]);

  if (!trelloDetailData || !shipList) {
    return (
      <Modal
        isOpened={showScheduleConfirmModal}
        handleClose={handleModalCancel}
        modalBody={<Card />}
      />
    );
  }

  return (
    <>
      <Modal
        isOpened={showScheduleConfirmModal}
        handleClose={handleModalCancel}
        modalBody={
          <Grid
            container
            direction={"column"}
            justifyContent={"space-between"}
            sx={{
              minWidth: 300,
              minHeight: 120,
            }}
          >
            <Typography variant="h5">스케줄 변동이 있습니까?</Typography>

            <Grid item container justifyContent={"space-between"}>
              <Button variant="outlined" onClick={handleTrelloModalOpenClick}>
                나중에 하기
              </Button>

              <Grid item>
                <Button
                  variant="outlined"
                  onClick={handleShipScheduleConfirmClick}
                  sx={{ mr: 1.5 }}
                >
                  아니오
                </Button>

                <Button
                  variant="contained"
                  onClick={handleEditShipScheduleModalClick}
                >
                  예
                </Button>
              </Grid>
            </Grid>
          </Grid>
        }
      />

      {showsEditShipScheduleModal && (
        <EditShipScheduleModal
          freightType={trelloDetailData.freightType}
          setShowsEditShipScheduleModal={setShowsEditShipScheduleModal}
          showsEditShipScheduleModal={showsEditShipScheduleModal}
          setShowsCheckChangeScheduleModal={setShowScheduleConfirmModal}
          bidId={bidId}
          management={trelloDetailData.management}
          shipList={shipList.list}
          region={trelloDetailData.locale}
        />
      )}
    </>
  );
};

export default ScheduleConfirmModal;
