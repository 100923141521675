import { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  AutoComplete,
  Button,
  Descriptions,
  Input,
  message,
  Modal,
  Select,
  Switch,
} from "antd";
import { BaseOptionType, DefaultOptionType } from "antd/lib/select";
import { useAtomValue } from "jotai";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import ADMIN_BID_QUERY, {
  ADMIN_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_QUERY";
import {
  Country,
  Port,
  Zone,
} from "@sellernote/_shared/src/types/common/common";
import { AdminBidDetail } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { getCountryCodeKR } from "@sellernote/_shared/src/utils/common/country";
import {
  getCustomsPartnerName,
  getShipmentServiceTypeKr,
} from "@sellernote/_shared/src/utils/forwarding/adminBid";
import { getAdminBidPortName } from "@sellernote/_shared/src/utils/forwarding/bid";
import {
  changeInlandTypeToNameKr,
  changeTransPortTypeToKr,
} from "@sellernote/_shared/src/utils/forwarding/trello";

import ZoneAddressTextFiled from "../../../../../containers/ZoneAddressTextField";

import { FORWARDING_ADMIN_AUTH_SELECTORS } from "../../../../../jotaiStates/auth";

const { Option } = Select;

const { confirm } = Modal;

// TODO: 수출 MVP 배포 후 의뢰 상세 인터페이스 작업 시 MUI로 리팩토링
const TransportationInfo = ({
  bidDetail,
  portData,
  zoneData,
  countryList,
}: {
  bidDetail: AdminBidDetail;
  portData: Port[];
  zoneData: Zone[];
  countryList: Country[];
}) => {
  const currentAdminAuthInfo = useAtomValue(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_FORWARDING_ADMIN_AUTH_INFO
  );

  const queryClient = useQueryClient();

  const [changeBid, setChangeBid] = useState(false);
  const [updateData, setUpdateData] = useState({});
  const [incoterms, setIncoterms] = useState(false);
  const [startType, setStartType] = useState(bidDetail.startType);
  const [endType, setEndType] = useState(bidDetail.endType);

  const {
    mutate: changeBidDetail,
    ResponseHandler: ResponseHandlerOfChangeBidDetail,
  } = ADMIN_BID_QUERY.useChangeBidDetail(bidDetail.id);

  const {
    mutate: changeExportBidDetail,
    ResponseHandler: ResponseHandlerOfChangeExportBidDetail,
  } = ADMIN_BID_QUERY.useChangeExportBidDetail(bidDetail.id);

  const { mutate: checkPriceReset } = ADMIN_BID_QUERY.useCheckPriceReset(
    bidDetail.id
  );

  const isExport = !bidDetail.isImport;

  const handleTransportationInfoChangeClick = useCallback(() => {
    const responseHandler = {
      onSuccess: () => {
        message.success("의뢰 정보를 변경했습니다.");
        setIncoterms(false);
        setChangeBid(false);
        setUpdateData({});
        queryClient.invalidateQueries(
          ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
            bidId: bidDetail.id,
          })
        );
      },

      onError: () => {
        message.error("에러가 있습니다. 개발자에게 문의해주세요.");
      },
    };
    confirm({
      title: "가격 재계산 혹은 리셋이 될 가능성이 있습니다.",
      icon: <ExclamationCircleOutlined />,
      content: "수정하시겠습니까??",
      onOk() {
        if (bidDetail.isImport) {
          checkPriceReset(updateData, {
            onSuccess: () => {
              changeBidDetail(updateData, {
                ...responseHandler,
              });
            },

            onError: () => {
              message.error("에러가 있습니다. 개발자에게 문의해주세요.");
            },
          });

          return;
        }

        changeExportBidDetail(updateData, {
          ...responseHandler,
        });

        return;
      },
    });
  }, [
    bidDetail.id,
    bidDetail.isImport,
    changeBidDetail,
    changeExportBidDetail,
    checkPriceReset,
    queryClient,
    updateData,
  ]);

  const checkDisabledAuthority = useCallback(() => {
    if (bidDetail.projectStatus === "settlementComplete") {
      if (currentAdminAuthInfo?.authority !== "master") {
        return true;
      }
      return false;
    }
    return false;
  }, [bidDetail.projectStatus, currentAdminAuthInfo?.authority]);

  const portNameListForAutoCompleteOptions = useMemo(
    () =>
      portData.map((v) => {
        return { value: v.nameEN };
      }),
    [portData]
  );

  const countryNameListForAutoCompleteOptions = useMemo(
    () =>
      countryList.map((v) => {
        return { value: v.nameKR };
      }),
    [countryList]
  );

  const endAddress = useMemo(() => {
    if (bidDetail.endAddressDetail) {
      return bidDetail.endAddress;
    }
    return bidDetail.zone?.name || "";
  }, [bidDetail.endAddress, bidDetail.endAddressDetail, bidDetail.zone?.name]);

  const getCountryName = useCallback(
    (countryName: string) => {
      return (
        countryList.find((v) => {
          return v.nameKR === countryName;
        })?.name || ""
      );
    },
    [countryList]
  );

  const changePortNameToId = useCallback(
    (portName: string) => {
      return (
        portData.find((v) => {
          return v.nameEN === portName;
        })?.id || null
      );
    },
    [portData]
  );

  const changeCheckpointValueToKr = useCallback((value: boolean | null) => {
    switch (value) {
      case true:
        return "포함";
      case false:
        return "미포함";
      default:
        return "-";
    }
  }, []);

  const changeContainerAccessableValueToKr = useCallback(
    (value: boolean | null) => {
      switch (value) {
        case true:
          return "가능";
        case false:
          return "불가능";
        default:
          return "-";
      }
    },
    []
  );

  const StartingPointDescriptionsItem = useMemo(() => {
    if (changeBid) {
      if (startType === "sea" || startType === "air") {
        return (
          <Descriptions.Item
            contentStyle={{ marginTop: -5 }}
            label="출발지 항구/공항 :"
            span={2}
          >
            <AutoComplete
              allowClear
              style={{ width: "100%", maxWidth: 237 }}
              onChange={(e) => {
                setUpdateData({
                  ...updateData,
                  startPortId: changePortNameToId(e),
                  startAddress: null,
                  startViaPortId: null,
                });
              }}
              defaultValue={getAdminBidPortName(bidDetail, "startPort")}
              options={portNameListForAutoCompleteOptions}
              filterOption={(
                inputValue: string,
                option: DefaultOptionType | BaseOptionType | undefined
              ) =>
                !!option?.value.toUpperCase().includes(inputValue.toUpperCase())
              }
            />
          </Descriptions.Item>
        );
      }
      if (startType === "inland") {
        return (
          <>
            {!isExport && (
              <Descriptions.Item
                contentStyle={{ marginTop: -5 }}
                label="출발지 내륙 :"
              >
                <Input
                  style={{ maxWidth: 266 }}
                  onChange={(e) => {
                    setUpdateData({
                      ...updateData,
                      startAddress: e.target.value,
                      startPortId: null,
                    });
                  }}
                  defaultValue={bidDetail.startAddress}
                ></Input>
              </Descriptions.Item>
            )}

            {isExport && (
              <Descriptions.Item
                contentStyle={{ marginTop: -5 }}
                label="출발지 내륙 :"
              >
                <ZoneAddressTextFiled
                  zoneName={bidDetail.startAddress}
                  pageType="bid"
                  setBidUpdateState={setUpdateData}
                  updateData={updateData}
                  isImport={true}
                />
              </Descriptions.Item>
            )}

            {isExport && (
              <Descriptions.Item
                contentStyle={{ marginTop: -5 }}
                label="출발지 상세주소 :"
              >
                <Input
                  allowClear
                  style={{ width: "100%", maxWidth: 266 }}
                  onChange={(e) => {
                    setUpdateData({
                      ...updateData,
                      startAddressDetail: e.target.value,
                    });
                  }}
                  defaultValue={bidDetail.startAddressDetail}
                />
              </Descriptions.Item>
            )}

            <Descriptions.Item
              contentStyle={{ marginTop: -5 }}
              label="출발지 경유지 :"
            >
              <AutoComplete
                allowClear
                style={{ width: "100%", maxWidth: 254 }}
                onChange={(e) => {
                  setUpdateData({
                    ...updateData,
                    startViaPortId: changePortNameToId(e),
                  });
                }}
                defaultValue={bidDetail.startViaPort?.nameEN}
                options={portNameListForAutoCompleteOptions}
                filterOption={(
                  inputValue: string,
                  option: DefaultOptionType | BaseOptionType | undefined
                ) =>
                  !!option?.value
                    .toUpperCase()
                    .includes(inputValue.toUpperCase())
                }
              />
            </Descriptions.Item>
          </>
        );
      }
    }
    if (startType === "sea" || startType === "air") {
      return (
        <Descriptions.Item label="출발지 항구/공항 :" span={2}>
          {getAdminBidPortName(bidDetail, "startPort")}
        </Descriptions.Item>
      );
    }
    return (
      <>
        <Descriptions.Item label="출발지 내륙 :">
          {getAdminBidPortName(bidDetail, "startPort")}
        </Descriptions.Item>

        {isExport && (
          <Descriptions.Item label="출발지 상세주소 :">
            {bidDetail.startAddressDetail}
          </Descriptions.Item>
        )}

        <Descriptions.Item label="출발지 경유지 :">
          {bidDetail.startViaPort?.nameEN}
        </Descriptions.Item>
      </>
    );
  }, [
    bidDetail,
    changeBid,
    changePortNameToId,
    isExport,
    portNameListForAutoCompleteOptions,
    startType,
    updateData,
  ]);

  const DestinationDescription = useMemo(() => {
    if (changeBid) {
      if (endType === "sea" || endType === "air") {
        return (
          <Descriptions.Item
            contentStyle={{ marginTop: -5 }}
            label="도착지 항구/공항 :"
            span={2}
          >
            <AutoComplete
              allowClear
              style={{ width: "100%", maxWidth: 237 }}
              onChange={(e) => {
                setUpdateData({
                  ...updateData,
                  endPortId: changePortNameToId(e),
                  endAddress: null,
                  endViaPortId: null,
                });
              }}
              defaultValue={getAdminBidPortName(bidDetail, "endPort")}
              options={portNameListForAutoCompleteOptions}
              filterOption={(
                inputValue: string,
                option: DefaultOptionType | BaseOptionType | undefined
              ) =>
                !!option?.value.toUpperCase().includes(inputValue.toUpperCase())
              }
            />
          </Descriptions.Item>
        );
      }
      if (endType === "inland") {
        return (
          <>
            {isExport ? (
              <Descriptions.Item
                contentStyle={{ marginTop: -5 }}
                label="도착지 내륙 :"
              >
                <Input
                  style={{ maxWidth: 266 }}
                  onChange={(e) => {
                    setUpdateData({
                      ...updateData,
                      endAddress: e.target.value,
                      startPortId: null,
                    });
                  }}
                  defaultValue={bidDetail.endAddress}
                ></Input>
              </Descriptions.Item>
            ) : (
              <Descriptions.Item
                contentStyle={{ marginTop: -5 }}
                label="도착지 내륙 :"
              >
                <ZoneAddressTextFiled
                  zoneName={endAddress}
                  pageType="bid"
                  setBidUpdateState={setUpdateData}
                  updateData={updateData}
                />
              </Descriptions.Item>
            )}

            {!isExport && (
              <Descriptions.Item
                contentStyle={{ marginTop: -5 }}
                label="도착지 상세주소 :"
              >
                <Input
                  allowClear
                  style={{ width: "100%", maxWidth: 266 }}
                  onChange={(e) => {
                    setUpdateData({
                      ...updateData,
                      endAddressDetail: e.target.value,
                    });
                  }}
                  defaultValue={bidDetail.endAddressDetail}
                />
              </Descriptions.Item>
            )}

            <Descriptions.Item
              contentStyle={{ marginTop: -5 }}
              label="도착지 경유지 :"
            >
              <AutoComplete
                allowClear
                style={{ width: "100%", maxWidth: 254 }}
                onChange={(e) => {
                  setUpdateData({
                    ...updateData,
                    endViaPortId: changePortNameToId(e),
                  });
                }}
                defaultValue={bidDetail.endViaPort?.nameEN}
                options={portNameListForAutoCompleteOptions}
                filterOption={(
                  inputValue: string,
                  option: DefaultOptionType | BaseOptionType | undefined
                ) =>
                  !!option?.value
                    .toUpperCase()
                    .includes(inputValue.toUpperCase())
                }
              />
            </Descriptions.Item>

            <Descriptions.Item span={2} label="zoneId :">
              {bidDetail.zoneId || "없음"}
            </Descriptions.Item>
          </>
        );
      }
    }
    if (endType === "sea" || endType === "air") {
      return (
        <Descriptions.Item label="도착지 항구/공항 :" span={2}>
          {getAdminBidPortName(bidDetail, "endPort")}
        </Descriptions.Item>
      );
    }
    return (
      <>
        <Descriptions.Item label="도착지 주소 :">
          {getAdminBidPortName(bidDetail, "endPort")}
        </Descriptions.Item>

        <Descriptions.Item label="도착지 상세주소 :">
          {bidDetail.endAddressDetail}
        </Descriptions.Item>

        <Descriptions.Item label="도착 경유지 항구">
          {bidDetail.endViaPort?.nameEN}
        </Descriptions.Item>

        <Descriptions.Item span={2} label="zoneId">
          {bidDetail.zoneId || "없음"}
        </Descriptions.Item>
      </>
    );
  }, [
    changeBid,
    endType,
    bidDetail,
    portNameListForAutoCompleteOptions,
    updateData,
    changePortNameToId,
    isExport,
    endAddress,
  ]);

  const EditButton = useMemo(() => {
    if (APP_NAME === "shipda-admin") {
      return changeBid ? (
        <>
          <Button
            onClick={handleTransportationInfoChangeClick}
            type="primary"
            style={{
              width: 154,
              backgroundColor: "rgba(24, 144, 255, 0.1)",
              color: "#1890FF",
              fontWeight: "bold",
            }}
          >
            완료
          </Button>

          <Button
            danger
            onClick={() => {
              setChangeBid(false);
              setIncoterms(false);
              setUpdateData({});
            }}
            style={{
              width: 154,
              fontWeight: "bold",
            }}
          >
            취소
          </Button>
        </>
      ) : (
        <Button
          style={{
            width: 154,
            backgroundColor: "#1890FF",
            fontWeight: "bold",
          }}
          type="primary"
          onClick={() => {
            if (checkDisabledAuthority()) {
              message.warning("정산완료에서는 수정할 수 없습니다.");
            } else {
              setChangeBid(true);
            }
          }}
        >
          수정
        </Button>
      );
    }
    return;
  }, [changeBid, checkDisabledAuthority, handleTransportationInfoChangeClick]);

  return (
    <div>
      <Descriptions
        extra={EditButton}
        key="containerAccessableFalse"
        title={<div style={{ fontWeight: "bold", fontSize: 20 }}>운송정보</div>}
        colon={false}
        contentStyle={{ fontWeight: "bold" }}
      >
        <Descriptions.Item
          label={
            <div style={{ color: "#1890FF", fontWeight: "bold", fontSize: 16 }}>
              ‣ 운영
            </div>
          }
          span={3}
        >
          {""}
        </Descriptions.Item>

        <Descriptions.Item label="유형 :" span={3}>
          {getShipmentServiceTypeKr({
            serviceType: bidDetail.serviceType,
            isExpress: bidDetail.isExpress,
            isImport: bidDetail.isImport,
          })}
        </Descriptions.Item>

        <Descriptions.Item label="국가 :">
          {changeBid ? (
            <AutoComplete
              allowClear
              style={{
                width: "100%",
                maxWidth: 306,
                fontWeight: "normal",
                marginTop: -5,
              }}
              onChange={(e) => {
                setUpdateData({
                  ...updateData,
                  startCountry: getCountryName(e),
                });
              }}
              defaultValue={getCountryCodeKR(bidDetail.startCountry)}
              options={countryNameListForAutoCompleteOptions}
              filterOption={(
                inputValue: string,
                option: DefaultOptionType | BaseOptionType | undefined
              ) =>
                !!option?.value.toUpperCase().includes(inputValue.toUpperCase())
              }
            />
          ) : (
            getCountryCodeKR(bidDetail.startCountry)
          )}
        </Descriptions.Item>

        <Descriptions.Item label="인코텀즈 :">
          {changeBid ? (
            <Select
              style={{
                width: "100%",
                maxWidth: 281,
                fontWeight: "normal",
                marginTop: -5,
              }}
              defaultValue={bidDetail.incoterms}
              onChange={(e) => {
                setIncoterms(true);
                setUpdateData({
                  ...updateData,
                  incoterms: e === bidDetail.incoterms ? undefined : e,
                });
              }}
            >
              <Option value="FOB">FOB</Option>
              <Option value="EXW">EXW</Option>
              <Option value="FCA">FCA</Option>
              <Option value="CIF">CIF</Option>
              <Option value="CFR">CFR</Option>
              <Option value="CPT">CPT</Option>
              <Option value="CIP">CIP</Option>
              <Option value="DDP">DDP</Option>
              <Option value="DAP">DAP</Option>
            </Select>
          ) : (
            bidDetail.incoterms
          )}
        </Descriptions.Item>

        <Descriptions.Item>{""}</Descriptions.Item>

        <Descriptions.Item label="출발지 유형 :">
          {incoterms ? (
            <Select
              style={{
                width: "100%",
                maxWidth: 266,
                marginTop: -5,
                fontWeight: "normal",
              }}
              defaultValue={bidDetail.startType}
              onChange={(e) => {
                setUpdateData({ ...updateData, startType: e });
                setStartType(e);
              }}
            >
              <Option value="sea">해상</Option>
              <Option value="air">항공</Option>
              <Option value="inland">내륙</Option>
            </Select>
          ) : (
            changeTransPortTypeToKr(bidDetail.startType)
          )}
        </Descriptions.Item>

        {StartingPointDescriptionsItem}

        <Descriptions.Item label="도착지 유형 :">
          {incoterms ? (
            <Select
              style={{
                width: "100%",
                maxWidth: 266,
                marginTop: -5,
                fontWeight: "normal",
              }}
              defaultValue={bidDetail.endType}
              onChange={(e) => {
                setUpdateData({ ...updateData, endType: e });
                setEndType(e);
              }}
            >
              <Option value="sea">해상</Option>
              <Option value="air">공항</Option>
              <Option value="inland">내륙</Option>
            </Select>
          ) : (
            changeTransPortTypeToKr(bidDetail.endType)
          )}
        </Descriptions.Item>

        {DestinationDescription}

        {!bidDetail.isImport && (
          <>
            <Descriptions.Item
              label={
                <div
                  style={{ color: "#1890FF", fontWeight: "bold", fontSize: 16 }}
                >
                  ‣ 부가서비스
                </div>
              }
              span={3}
            >
              {""}
            </Descriptions.Item>

            <Descriptions.Item label="수출통관 :">
              {changeBid ? (
                <Switch
                  defaultChecked={bidDetail.containExportCustoms}
                  onChange={(checked) => {
                    setUpdateData({
                      ...updateData,
                      containExportCustoms: checked,
                    });
                  }}
                ></Switch>
              ) : (
                changeCheckpointValueToKr(bidDetail.containExportCustoms)
              )}
            </Descriptions.Item>

            <Descriptions.Item label="적하보험 :">
              {changeBid ? (
                <Switch
                  defaultChecked={bidDetail.hopeCargoInsurance}
                  onChange={(checked) => {
                    setUpdateData({
                      ...updateData,
                      hopeCargoInsurance: checked,
                    });
                  }}
                ></Switch>
              ) : (
                changeCheckpointValueToKr(bidDetail.hopeCargoInsurance)
              )}
            </Descriptions.Item>

            <Descriptions.Item label="VGM :">
              {changeBid ? (
                <Switch
                  defaultChecked={bidDetail.useVGM}
                  onChange={(checked) => {
                    setUpdateData({ ...updateData, useVGM: checked });
                  }}
                ></Switch>
              ) : bidDetail.useVGM ? (
                "포함"
              ) : bidDetail.freightType === "FCL" ? (
                "불포함"
              ) : (
                "-"
              )}
            </Descriptions.Item>

            <Descriptions.Item label="수입통관 :" span={3}>
              {changeBid ? (
                <Switch
                  defaultChecked={bidDetail.containCustoms}
                  onChange={(checked) => {
                    setUpdateData({ ...updateData, containCustoms: checked });
                  }}
                ></Switch>
              ) : (
                changeCheckpointValueToKr(bidDetail.containCustoms)
              )}
            </Descriptions.Item>
          </>
        )}

        <Descriptions.Item
          span={3}
          label={
            <div style={{ color: "#1890FF", fontWeight: "bold", fontSize: 16 }}>
              {isExport ? "‣ 체크포인트-수출지" : "‣ 체크포인트"}
            </div>
          }
        >
          {""}
        </Descriptions.Item>

        {!bidDetail.isImport && (
          <>
            <Descriptions.Item label="컨테이너 적입여부 :">
              {changeBid ? (
                <Switch
                  defaultChecked={bidDetail.containerStuffing}
                  onChange={(checked) => {
                    setUpdateData({
                      ...updateData,
                      containerStuffing: checked,
                    });
                  }}
                ></Switch>
              ) : bidDetail.containerStuffing ? (
                "직접(고객사)"
              ) : bidDetail.freightType !== "FCL" ? (
                "-"
              ) : (
                "미신청"
              )}
            </Descriptions.Item>

            <Descriptions.Item label="CFS 입고 :">
              {changeBid ? (
                <Select
                  style={{
                    width: "100%",
                    maxWidth: 266,
                    marginTop: -5,
                    fontWeight: "normal",
                  }}
                  defaultValue={bidDetail.cfsReceiving}
                  onChange={(e) => {
                    if (!e) {
                      setUpdateData({ ...updateData, cfsReceiving: undefined });
                    }
                    setUpdateData({ ...updateData, cfsReceiving: e });
                  }}
                >
                  <Option value="direct">직접(고객사)</Option>
                  <Option value="shipda">대행(쉽다)</Option>
                  <Option value="none">-</Option>
                </Select>
              ) : bidDetail.cfsReceiving === "direct" ? (
                "직접"
              ) : bidDetail.cfsReceiving === "shipda" ? (
                "대행"
              ) : (
                "-"
              )}
            </Descriptions.Item>

            <Descriptions.Item label="운송방식 :">
              {changeBid ? (
                <Select
                  style={{
                    width: "100%",
                    maxWidth: 266,
                    marginTop: -5,
                    fontWeight: "normal",
                  }}
                  // 수출에서는 originInland를 사용한다.
                  defaultValue={
                    isExport ? bidDetail.originInland : bidDetail.inlandType
                  }
                  onChange={(e) => {
                    if (isExport) {
                      setUpdateData({
                        ...updateData,
                        originInland: e ?? undefined,
                      });
                    } else {
                      setUpdateData({
                        ...updateData,
                        inlandType: e ?? undefined,
                      });
                    }
                  }}
                >
                  <Option value="sole">독차</Option>
                  <Option value="consol">합차</Option>
                  <Option value="none">-</Option>
                </Select>
              ) : (
                changeInlandTypeToNameKr(
                  isExport ? bidDetail.originInland : bidDetail.inlandType
                )
              )}
            </Descriptions.Item>
          </>
        )}

        {bidDetail.isImport && (
          <>
            <Descriptions.Item label="국내부대비용 포함 :">
              {changeBid ? (
                <Switch
                  defaultChecked={bidDetail.containDomesticFee}
                  onChange={(checked) => {
                    setUpdateData({
                      ...updateData,
                      containDomesticFee: checked,
                    });
                  }}
                ></Switch>
              ) : (
                changeCheckpointValueToKr(bidDetail.containDomesticFee)
              )}
            </Descriptions.Item>

            {bidDetail.freightType !== "AIR" && (
              <Descriptions.Item label="OCEAN SURCHARGE 포함:">
                {changeBid ? (
                  <Switch
                    defaultChecked={bidDetail.containOceanSurcharge}
                    onChange={(checked) => {
                      setUpdateData({
                        ...updateData,
                        containOceanSurcharge: checked,
                      });
                    }}
                  ></Switch>
                ) : (
                  changeCheckpointValueToKr(bidDetail.containOceanSurcharge)
                )}
              </Descriptions.Item>
            )}

            <Descriptions.Item label="LSS :">
              {changeBid ? (
                <Switch
                  defaultChecked={bidDetail.containLss}
                  onChange={(checked) => {
                    setUpdateData({ ...updateData, containLss: checked });
                  }}
                ></Switch>
              ) : (
                changeCheckpointValueToKr(bidDetail.containLss)
              )}
            </Descriptions.Item>

            <Descriptions.Item label="적하보험 :">
              {changeBid ? (
                <Switch
                  defaultChecked={bidDetail.hopeCargoInsurance}
                  onChange={(checked) => {
                    setUpdateData({
                      ...updateData,
                      hopeCargoInsurance: checked,
                    });
                  }}
                ></Switch>
              ) : (
                changeCheckpointValueToKr(bidDetail.hopeCargoInsurance)
              )}
            </Descriptions.Item>

            <Descriptions.Item label="통관의뢰 :">
              {changeBid ? (
                <Switch
                  defaultChecked={bidDetail.containCustoms}
                  onChange={(checked) => {
                    setUpdateData({ ...updateData, containCustoms: checked });
                  }}
                ></Switch>
              ) : (
                `${
                  bidDetail.containCustoms
                    ? changeCheckpointValueToKr(bidDetail.containCustoms)
                    : `미포함 (${getCustomsPartnerName(
                        bidDetail.accountPayables
                      )})`
                }`
              )}
            </Descriptions.Item>

            {bidDetail.freightType === "FCL" && (
              <Descriptions.Item label="도착지 컨테이너 진입여부 :">
                {changeBid ? (
                  <Switch
                    defaultChecked={bidDetail.containerAccessable}
                    onChange={(checked) => {
                      setUpdateData({
                        ...updateData,
                        containerAccessable: checked,
                      });
                    }}
                  ></Switch>
                ) : (
                  changeContainerAccessableValueToKr(
                    bidDetail.containerAccessable
                  )
                )}
              </Descriptions.Item>
            )}

            {bidDetail.locale === "SG" && (
              <Descriptions.Item label="IOR :">
                {changeBid ? (
                  <Switch
                    defaultChecked={bidDetail.needIORAgency}
                    onChange={(checked) => {
                      setUpdateData({ ...updateData, needIORAgency: checked });
                    }}
                  ></Switch>
                ) : (
                  changeCheckpointValueToKr(bidDetail.needIORAgency)
                )}
              </Descriptions.Item>
            )}

            <Descriptions.Item label="FTA C/O :">
              {changeBid ? (
                <Switch
                  defaultChecked={bidDetail.needFTACertificateAgency}
                  onChange={(checked) => {
                    setUpdateData({
                      ...updateData,
                      needFTACertificateAgency: checked,
                    });
                  }}
                ></Switch>
              ) : (
                changeCheckpointValueToKr(bidDetail.needFTACertificateAgency)
              )}
            </Descriptions.Item>

            {bidDetail.freightType !== "FCL" && (
              <Descriptions.Item label="합차/독차 :">
                {changeInlandTypeToNameKr(bidDetail.inlandType)}
              </Descriptions.Item>
            )}

            <Descriptions.Item span={3} label="유저 코멘트 :">
              {bidDetail.userNote}
            </Descriptions.Item>
          </>
        )}

        {isExport && (
          <Descriptions.Item
            span={3}
            label={
              <div
                style={{ color: "#1890FF", fontWeight: "bold", fontSize: 16 }}
              >
                ‣ 체크포인트-수입지
              </div>
            }
          >
            {""}
          </Descriptions.Item>
        )}

        {isExport && (
          <Descriptions.Item label="컨테이너 적출여부 :" span={3}>
            {changeBid ? (
              <Switch
                defaultChecked={bidDetail.containerDevanning}
                onChange={(checked) => {
                  setUpdateData({
                    ...updateData,
                    containerDevanning: checked,
                  });
                }}
              ></Switch>
            ) : bidDetail.containerDevanning ? (
              "신청"
            ) : endType !== "inland" || bidDetail.freightType !== "FCL" ? (
              "-"
            ) : (
              "미신청"
            )}
          </Descriptions.Item>
        )}
      </Descriptions>

      {ResponseHandlerOfChangeBidDetail}

      {ResponseHandlerOfChangeExportBidDetail}
    </div>
  );
};

export default TransportationInfo;
