import { useMemo } from "react";
import { Box } from "@mui/material";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import { TrelloBidList } from "@sellernote/_shared/src/types/forwarding/trello";

import TrelloCardCheckBox from "./TrelloCardCheckBox";
import { CardListData } from "./TrelloCardList";

// TODO: dev API 및 인터페이스 정리가 안된 상태 배포되면 추가 작업
export default function useExportTrelloList({
  trelloListData,
  getTrelloCardTitle,
  getTrelloCardCommonBody,
  handleTrelloCardClick,
}: {
  trelloListData: TrelloBidList[];
  getTrelloCardTitle: (cardData: TrelloBidList) => JSX.Element;
  getTrelloCardCommonBody: (trelloListData: TrelloBidList) => JSX.Element;
  handleTrelloCardClick: (cardData: TrelloBidList) => void;
}) {
  const exportCardListData: CardListData[] = useMemo(() => {
    return [
      {
        lineKey: "1",
        lineTitle: "화물 준비 중",
        cards: trelloListData
          .filter((v: TrelloBidList) => v.projectStatus === "waitingFreight")
          .map((v: TrelloBidList) => {
            return {
              forwardingManagerId: v.management.forwardingManagerId,
              key: v.id.toString(),
              title: getTrelloCardTitle(v),
              freightType: `${v.freightType}/${v.incoterms}`,
              description: <Box>{getTrelloCardCommonBody(v)}</Box>,
              handleTrelloCardClick: () => handleTrelloCardClick(v),
            };
          }),
      },
      {
        lineKey: "2",
        lineTitle: "출항 준비 중",
        cards: trelloListData
          .filter(
            (v: TrelloBidList) =>
              v.projectStatus === "scheduling" ||
              v.projectStatus === "containerCarryOut" ||
              v.projectStatus === "containerPickup" ||
              v.projectStatus === "gateIn" ||
              v.projectStatus === "loaded" ||
              v.projectStatus === "domesticImportCFS"
          )
          .map((v: TrelloBidList) => {
            return {
              forwardingManagerId: v.management.forwardingManagerId,
              key: v.id.toString(),
              title: getTrelloCardTitle(v),
              freightType: `${v.freightType}/${v.incoterms}`,
              projectStatus: v.projectStatus,
              description: (
                <Box>
                  {getTrelloCardCommonBody(v)}

                  {APP_NAME === "shipda-admin" && (
                    <>
                      <TrelloCardCheckBox
                        label="수출신고수리"
                        checkedValue={false}
                      />
                    </>
                  )}
                </Box>
              ),
              handleTrelloCardClick: () => handleTrelloCardClick(v),
            };
          }),
      },
      {
        lineKey: "3",
        lineTitle: "출항 완료",
        cards: trelloListData
          .filter((v: TrelloBidList) => v.projectStatus === "moving")
          .map((v: TrelloBidList) => {
            return {
              forwardingManagerId: v.management.forwardingManagerId,
              key: v.id.toString(),
              title: getTrelloCardTitle(v),
              freightType: `${v.freightType}/${v.incoterms}`,
              description: <Box>{getTrelloCardCommonBody(v)}</Box>,
              handleTrelloCardClick: () => handleTrelloCardClick(v),
            };
          }),
      },
      {
        lineKey: "4",
        lineTitle: "입항 완료",
        cards: trelloListData
          .filter(
            (v: TrelloBidList) =>
              v.projectStatus === "portEntryAndPrepareCustoms"
          )
          .map((v: TrelloBidList) => {
            return {
              forwardingManagerId: v.management.forwardingManagerId,
              key: v.id.toString(),
              title: getTrelloCardTitle(v),
              freightType: `${v.freightType}/${v.incoterms}`,
              description: (
                <Box>
                  {getTrelloCardCommonBody(v)}
                  {v.incoterms.includes("C") && (
                    <TrelloCardCheckBox
                      label="거래명세서 발송(고객)"
                      checkedValue={
                        v.management.customsPaymentFlag ||
                        v.invoices[0]?.isSended
                      }
                    />
                  )}
                </Box>
              ),
              handleTrelloCardClick: () => handleTrelloCardClick(v),
            };
          }),
      },

      {
        lineKey: "5",
        lineTitle: "통관 완료",
        cards: trelloListData
          .filter((v: TrelloBidList) => v.projectStatus === "completeCustoms")
          .map((v: TrelloBidList) => {
            return {
              forwardingManagerId: v.management.forwardingManagerId,
              key: v.id.toString(),
              title: getTrelloCardTitle(v),
              freightType: `${v.freightType}/${v.incoterms}`,
              description: <Box>{getTrelloCardCommonBody(v)}</Box>,
              handleTrelloCardClick: () => handleTrelloCardClick(v),
            };
          }),
      },
      {
        lineKey: "8",
        lineTitle: "내륙 운송 중",
        cards: trelloListData
          .filter((v: TrelloBidList) => v.projectStatus === "delivering")
          .map((v: TrelloBidList) => {
            return {
              forwardingManagerId: v.management.forwardingManagerId,
              key: v.id.toString(),
              title: getTrelloCardTitle(v),
              freightType: `${v.freightType}/${v.incoterms}`,
              description: <Box>{getTrelloCardCommonBody(v)}</Box>,
              handleTrelloCardClick: () => handleTrelloCardClick(v),
            };
          }),
      },
      {
        lineKey: "9",
        lineTitle: "종료",
        cards: trelloListData
          .filter((v: TrelloBidList) => v.projectStatus === "finished")
          .map((v: TrelloBidList) => {
            return {
              forwardingManagerId: v.management.forwardingManagerId,
              key: v.id.toString(),
              title: getTrelloCardTitle(v),
              freightType: `${v.freightType}/${v.incoterms}`,
              description: (
                <Box>
                  {getTrelloCardCommonBody(v)}

                  {v.incoterms.includes("D") && (
                    <TrelloCardCheckBox
                      label="거래명세서 발송(고객)"
                      checkedValue={
                        v.management.customsPaymentFlag ||
                        v.invoices[0]?.isSended
                      }
                    />
                  )}
                </Box>
              ),
              handleTrelloCardClick: () => handleTrelloCardClick(v),
            };
          }),
      },
    ];
  }, [
    getTrelloCardCommonBody,
    getTrelloCardTitle,
    handleTrelloCardClick,
    trelloListData,
  ]);

  return { exportCardListData };
}
