import { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

import { CHANGE_SCHEDULE_REASON_OPTION_LIST } from "@sellernote/_shared/src/constants/forwarding/vesselSchedule";
import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import { SellernoteAppRegion } from "@sellernote/_shared/src/types/common/common";
import { ShipList } from "@sellernote/_shared/src/types/forwarding/ship";
import {
  ShippingScheduleUpdate,
  TrelloBidManagement,
} from "@sellernote/_shared/src/types/forwarding/trello";
import Modal from "@sellernote/_shared-for-admin/src/components/Modal";

import DatePicker from "../../../../../../../components/DatePicker";
import HighlightTextField from "../../../../../../../components/HighlightTextField";

import LOCALIZED_VESSEL_SCHEDULE_UPDATE_NOTICE from "./VESSEL_SCHEDULE_NOTICE";

const EditShipScheduleModal = ({
  setShowsEditShipScheduleModal,
  showsEditShipScheduleModal,
  bidId,
  setShowsCheckChangeScheduleModal,
  management,
  freightType,
  shipList,
  region,
}: {
  setShowsEditShipScheduleModal: (value: boolean) => void;
  showsEditShipScheduleModal: boolean;
  bidId: number;
  setShowsCheckChangeScheduleModal?: (value: boolean) => void;
  management: TrelloBidManagement;
  freightType: string;
  shipList?: ShipList[];
  region: SellernoteAppRegion;
}) => {
  const queryClient = useQueryClient();

  const [etd, setEtd] = useState<string | null>(management.ETD);
  const [eta, setEta] = useState<string | null>(management.ETA);
  const [cargoReady, setCargoReady] = useState<string | null>(
    management.cargoReady
  );
  const [shipName, setShipName] = useState<string | undefined>(
    management.shipName
  );
  const [changeReason, setChangeReason] = useState<string | undefined | null>(
    ""
  );
  const [voyageNumber, setVoyageNumber] = useState(management.voyageNumber);

  const {
    mutate: updateShipSchedule,
    ResponseHandler: ResponseHandlerOfUpdateShipSchedule,
  } = TRELLO_BID_QUERY.useUpdateShipSchedule({
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();
        if (setShowsCheckChangeScheduleModal) {
          // 스케줄 변경 확인 모달을 종료하기 위해 사용
          setShowsCheckChangeScheduleModal(false);
          queryClient.invalidateQueries(TRELLO_BID_QUERY_KEY_GEN.all());
          return;
        }

        setShowsEditShipScheduleModal(false);
        TRELLO_BID_QUERY_KEY_GEN.trelloDetail();
        return;
      },
      customizeMessage: () => ({
        title: "스케줄을 변경했습니다.",
      }),
    },
    bidId,
  });

  const shipNameList = useMemo(() => {
    return shipList?.map((v: ShipList) => {
      const obj = { value: "", label: "" };
      obj.value = v.name;
      obj.label = v.name;
      return obj;
    });
  }, [shipList]);

  const checkIfButtonIsDisabled = useCallback(() => {
    if (freightType === "AIR") {
      if ((etd === management.ETD && eta === management.ETA) || !changeReason) {
        return true;
      }
      return false;
    }

    if (voyageNumber !== management.voyageNumber && changeReason) return false;

    if (cargoReady !== management.cargoReady && changeReason) return false;

    if (
      (etd === management.ETD &&
        eta === management.ETA &&
        shipName === management.shipName) ||
      !changeReason
    ) {
      return true;
    }

    return false;
  }, [
    cargoReady,
    changeReason,
    eta,
    etd,
    freightType,
    management.ETA,
    management.ETD,
    management.cargoReady,
    management.shipName,
    management.voyageNumber,
    shipName,
    voyageNumber,
  ]);

  const getShipImo = useCallback(
    (name: string | undefined) => {
      if (!name) return;
      const findShipData = shipList?.find((v: ShipList) => {
        return v.name === name;
      });
      if (findShipData) {
        return findShipData.IMO;
      }
      return 0;
    },
    [shipList]
  );

  const getShowsHighlightsText = (type: ShippingScheduleUpdate) => {
    if (!management.shippingScheduleUpdate) {
      return false;
    }
    return management.shippingScheduleUpdate.includes(type);
  };

  const getSpecialIssue = () => {
    // 모선명, 항차만 변경하고 ETD, ETA는 변경하지 않았을 때
    if (
      eta === management.ETA &&
      etd === management.ETD &&
      (voyageNumber !== management.voyageNumber ||
        shipName !== management.shipName)
    ) {
      return "shipNameOrVoyageNo";
    }

    return "schedule";
  };

  return (
    <Modal
      isOpened={showsEditShipScheduleModal}
      handleClose={() => setShowsEditShipScheduleModal(false)}
      modalBody={
        <Grid container direction={"column"} spacing={5}>
          <Grid item container direction={"column"} alignItems="center">
            <Grid item>
              <Typography variant="h5" component={"div"}>
                ETD & ETA 재설정
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="body1">
                변경된 날짜를 선택하고 사유를 입력하세요.
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            container
            direction={"column"}
            spacing={2}
            alignItems="center"
          >
            <Grid item>
              <DatePicker
                when={"start"}
                value={cargoReady}
                setDate={setCargoReady}
                fullWidth={true}
                label="카고레디"
                isNotISOString={true}
              />
            </Grid>
            <Grid item>
              <DatePicker
                when={"end"}
                value={etd}
                setDate={setEtd}
                fullWidth={true}
                label="ETD"
                showsHighlightsText={getShowsHighlightsText("ETD")}
                isNotISOString={true}
              />
            </Grid>
            <Grid item>
              <DatePicker
                when={"start"}
                value={eta}
                setDate={setEta}
                fullWidth={true}
                label="ETA"
                showsHighlightsText={getShowsHighlightsText("ETA")}
                isNotISOString={true}
              />
            </Grid>
            {freightType !== "AIR" && shipNameList && (
              <Grid item container>
                <Autocomplete
                  size="small"
                  id="combo-box-demo"
                  options={shipNameList}
                  fullWidth
                  defaultValue={
                    shipName ? { value: shipName, label: shipName } : null
                  }
                  renderInput={(params) => (
                    <HighlightTextField
                      {...params}
                      label="모선명"
                      showshighlightstext={getShowsHighlightsText("shipName")}
                    />
                  )}
                  onChange={(
                    event,
                    newValue: { value: string; label: string } | null
                  ) => setShipName(newValue?.value)}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                />
              </Grid>
            )}
            <Grid item container>
              <TextField
                fullWidth
                onChange={(e) => setVoyageNumber(e.target.value)}
                value={voyageNumber || undefined}
                size="small"
                label={freightType === "AIR" ? "편명" : "항차"}
              />
            </Grid>

            <Grid item container>
              <Autocomplete
                size="small"
                id="combo-box-demo"
                options={CHANGE_SCHEDULE_REASON_OPTION_LIST}
                fullWidth
                freeSolo={true}
                value={changeReason}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="사유를 입력해주세요"
                    onChange={(event) => setChangeReason(event.target.value)}
                  />
                )}
                onChange={(event, newValue: string | null) => {
                  if (!newValue) {
                    return setChangeReason("");
                  }

                  setChangeReason(
                    LOCALIZED_VESSEL_SCHEDULE_UPDATE_NOTICE[newValue][region]
                  );
                }}
                isOptionEqualToValue={(option, value) => option === value}
              />
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                disabled={checkIfButtonIsDisabled()}
                onClick={() => {
                  if (freightType === "AIR") {
                    updateShipSchedule({
                      ETD: etd,
                      ETA: eta,
                      voyageNumber,
                      issueType: getSpecialIssue(),
                      scheduleChangeReason: changeReason || "",
                      shippingScheduleUpdate: management.shippingScheduleUpdate
                        ? true
                        : undefined,
                      cargoReady,
                    });
                  } else {
                    if (!shipName) return;
                    updateShipSchedule({
                      shipName,
                      shipIMO: getShipImo(shipName),
                      ETD: etd,
                      ETA: eta,
                      voyageNumber,
                      issueType: getSpecialIssue(),
                      scheduleChangeReason: changeReason || "",
                      shippingScheduleUpdate: management.shippingScheduleUpdate
                        ? true
                        : undefined,
                      cargoReady,
                    });
                  }
                }}
              >
                선택 완료
              </Button>
            </Grid>
          </Grid>

          {ResponseHandlerOfUpdateShipSchedule}
        </Grid>
      }
    />
  );
};

export default EditShipScheduleModal;
