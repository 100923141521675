import { TableHeadFilterOption } from "@sellernote/_shared-for-admin/src/hooks/common/useTableHeadFilter";

import { ForwardingManagersId } from "../../types/forwarding/adminUser";

import { IS_UNDER_PRODUCTION } from "../../constants";

const ADMIN_FORWARDING_MANAGERS = {
  sonSukKyun: {
    id: IS_UNDER_PRODUCTION ? 10 : 46,
    accountId: "max",
    name: "손석균",
  },

  jengJeongHoon: {
    id: IS_UNDER_PRODUCTION ? 11 : 51,
    accountId: "jhjeong",
    name: "정정훈",
  },

  hwangHoJin: {
    id: IS_UNDER_PRODUCTION ? 12 : 52,
    accountId: "hjhwang",
    name: "황호진",
  },

  leeCheolHaek: {
    id: IS_UNDER_PRODUCTION ? 14 : 48,
    accountId: "rick",
    name: "이철행",
  },

  yooHanNa: {
    id: IS_UNDER_PRODUCTION ? 27 : 75,
    accountId: "hnyu",
    name: "유한나",
  },

  oSholJi: {
    id: IS_UNDER_PRODUCTION ? 36 : 74,
    accountId: "blair",
    name: "오솔지",
  },

  joHyungGeun: {
    id: IS_UNDER_PRODUCTION ? 8 : 42,
    accountId: IS_UNDER_PRODUCTION ? "hyunggeun" : "geunee92",
    name: "조형근",
  },

  bangHyunJung: {
    id: IS_UNDER_PRODUCTION ? 16 : 55,
    accountId: "hjbang",
    name: "방현정",
  },

  choiYoungChan: {
    id: IS_UNDER_PRODUCTION ? 40 : 40,
    accountId: "ycchoi",
    name: "최용찬",
  },

  songJiWoo: {
    id: IS_UNDER_PRODUCTION ? 44 : 97,
    accountId: "joysong",
    name: "송지우",
  },

  leeJungho: {
    id: IS_UNDER_PRODUCTION ? 50 : 101,
    accountId: "test-admin",
    name: "이중호",
  },

  parkSeungHyeok: {
    id: IS_UNDER_PRODUCTION ? 53 : 107,
    accountId: "hyeok",
    name: "박승혁",
  },

  leeBoMi: {
    id: IS_UNDER_PRODUCTION ? 52 : 108,
    accountId: "BOMI",
    name: "이보미",
  },
};

const ADMIN_FORWARDING_MANAGER_OPTION_LIST: TableHeadFilterOption<ForwardingManagersId>[] =
  [
    {
      label: ADMIN_FORWARDING_MANAGERS.sonSukKyun.name,
      value: ADMIN_FORWARDING_MANAGERS.sonSukKyun.id,
    },
    {
      label: ADMIN_FORWARDING_MANAGERS.jengJeongHoon.name,
      value: ADMIN_FORWARDING_MANAGERS.jengJeongHoon.id,
    },
    {
      label: ADMIN_FORWARDING_MANAGERS.hwangHoJin.name,
      value: ADMIN_FORWARDING_MANAGERS.hwangHoJin.id,
    },
    {
      label: ADMIN_FORWARDING_MANAGERS.leeCheolHaek.name,
      value: ADMIN_FORWARDING_MANAGERS.leeCheolHaek.id,
    },
    {
      label: ADMIN_FORWARDING_MANAGERS.yooHanNa.name,
      value: ADMIN_FORWARDING_MANAGERS.yooHanNa.id,
    },
    {
      label: ADMIN_FORWARDING_MANAGERS.oSholJi.name,
      value: ADMIN_FORWARDING_MANAGERS.oSholJi.id,
    },
    {
      label: ADMIN_FORWARDING_MANAGERS.choiYoungChan.name,
      value: ADMIN_FORWARDING_MANAGERS.choiYoungChan.id,
    },
    {
      label: ADMIN_FORWARDING_MANAGERS.songJiWoo.name,
      value: ADMIN_FORWARDING_MANAGERS.songJiWoo.id,
    },
    {
      label: ADMIN_FORWARDING_MANAGERS.leeJungho.name,
      value: ADMIN_FORWARDING_MANAGERS.leeJungho.id,
    },
    {
      label: ADMIN_FORWARDING_MANAGERS.parkSeungHyeok.name,
      value: ADMIN_FORWARDING_MANAGERS.parkSeungHyeok.id,
    },
    {
      label: ADMIN_FORWARDING_MANAGERS.leeBoMi.name,
      value: ADMIN_FORWARDING_MANAGERS.leeBoMi.id,
    },
  ];

const ADMIN_FORWARDING_MANAGER_INFO_FOR_TRELLO_LIST = [
  {
    id: IS_UNDER_PRODUCTION ? 12 : 52,
    backgroundColor: "#e0f7e1",
    border: "#40c445",
    name: "황호진",
  },
  {
    id: IS_UNDER_PRODUCTION ? 11 : 51,
    backgroundColor: "#bae7ff",
    border: "#40a9ff",
    name: "정정훈",
  },
  {
    id: IS_UNDER_PRODUCTION ? 14 : 48,
    backgroundColor: "#efdbff",
    border: "#9254de",
    name: "이철행",
  },
  {
    id: IS_UNDER_PRODUCTION ? 10 : 46,
    backgroundColor: "#ffeed9",
    border: "#fe9820",
    name: "손석균",
  },
  {
    id: IS_UNDER_PRODUCTION ? 36 : 74,
    backgroundColor: "#ffd6e7",
    border: "#eb2f96",
    name: "오솔지",
  },
  {
    id: IS_UNDER_PRODUCTION ? 40 : 40,
    backgroundColor: "#aaa5dc",
    border: "#0078b4",
    name: "최용찬",
  },
  {
    id: IS_UNDER_PRODUCTION ? 50 : 101,
    backgroundColor: "#d7ccc8",
    border: "#6d4c41",
    name: "이중호",
  },
  {
    id: IS_UNDER_PRODUCTION ? 27 : 75,
    backgroundColor: "#b5f5ec",
    border: "#36cfc9",
    name: "유한나",
  },
  {
    id: IS_UNDER_PRODUCTION ? 44 : 97,
    backgroundColor: undefined,
    border: undefined,
    name: "송지우",
  },
  {
    id: IS_UNDER_PRODUCTION ? 53 : 107,
    backgroundColor: "#c0c0c0",
    border: "#424242",
    name: "박승혁",
  },
  {
    id: IS_UNDER_PRODUCTION ? 52 : 108,
    backgroundColor: "#e2b3a3",
    border: "#e2b3a3",
    name: "이보미",
  },
];

export {
  ADMIN_FORWARDING_MANAGERS,
  ADMIN_FORWARDING_MANAGER_OPTION_LIST,
  ADMIN_FORWARDING_MANAGER_INFO_FOR_TRELLO_LIST,
};
