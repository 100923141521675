import React, {
  Fragment,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
  Box,
  Paper,
  SxProps,
  Tab,
  Table as MUITable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  Theme,
  Toolbar,
  Typography,
} from "@mui/material";
import { blue } from "@mui/material/colors";

import useIntersectionObserver from "@sellernote/_shared/src/hooks/common/useIntersectionObserver";
import { isEmptyObjectOrArray } from "@sellernote/_shared/src/utils/common/etc";
import { omitWithEllipsis } from "@sellernote/_shared/src/utils/common/string";

import CSVDownload, { TableCSVDownloadInfo } from "./CSVDownload";
import Styled from "./index.styles";

export type TableGroupedHeadRow<GroupedHeadId, CellId> =
  TableGroupedHeadRowItem<GroupedHeadId, CellId>[];

export interface TableGroupedHeadRowItem<GroupedHeadId, CellId> {
  id: GroupedHeadId;
  label: string;
  headCellIds: CellId[];
}

export interface TableTabInfo {
  activeTabIndex: number;
  setActiveTabIndex: (val: number) => void;
  tabList: { label: ReactNode }[];
}

export type TableHeadCell<CellId> = {
  id: CellId;
  label: React.ReactNode;
  /**
   * label이 JSX인 경우 대체할 값
   */
  labelForCSV?: string | number;
  disablePadding: boolean;
  width?: number;
  numeric?: boolean;
  /**
   * 내용의 최대 길이를 정하고 싶을때 사용
   * string의 length를 지정
   * 초과하는 내용은 omitWithEllipsis함수를 통해 축약형으로 표시됨.
   */
  maxContentLength?: number;
  filter?: React.ReactNode;
  verticalAlign?: "top" | "baseline" | "bottom" | "middle";
  isFixed?: boolean;
  displayFixedNumber?: number;
};

export type TableBodyRow<CellId extends string> = {
  [K in CellId]?: string | number | React.ReactNode;
} & {
  handleRowClick?: () => void;
  handleRowAdd?: () => void;
  handleRowRemove?: () => void;
  backgroundColor?: string;
};

const cellBorderStyle = "1px solid rgba(224,224,224,1)";

export default function Table<CellId extends string, GroupedHeadId = void>({
  title,
  groupedHeadRow,
  headCells,
  rows,
  extraRows,
  pagination,
  toolbarItems,
  tableTabInfo,
  csvDownloadInfo,
  sx,
  minWidth,
  className,
  isFixedHeader,
  showsEmptyTableImage,
  showsNoDataFound,
  isLoading,
  showsTableVerticalLine,
  scrollbarSize = "default",
}: {
  title?: React.ReactNode;
  /**
   * 헤더의 상위헤더(그루핑된 헤더)
   * 일단은 1depth만 있다고 가정. (2depth 이상 되면 개선 필요)
   */
  groupedHeadRow?: TableGroupedHeadRow<GroupedHeadId, CellId>;
  headCells: TableHeadCell<CellId>[];
  rows: TableBodyRow<CellId>[];
  /**
   * 테이블 하단에 추가로 표시할 행 (ex. 총합계)
   */
  extraRows?: ReactNode;
  pagination?: {
    rowsPerPageOptions?:
      | (
          | number
          | {
              value: number;
              label: string;
            }
        )[]
      | undefined;
    totalCount: number;
    perPage: number;
    setPerPage: (perPage: number) => void;
    currentPage: number;
    setCurrentPage: (page: number) => void;
  };
  toolbarItems?: { left?: React.ReactNode[]; right?: React.ReactNode[] };
  tableTabInfo?: TableTabInfo;
  /**
   * csv download 기능이 필요할때만 사용.
   * cell 값이 string | number 외의 형태면 이상작동할 수 있음에 유의
   */
  csvDownloadInfo?: TableCSVDownloadInfo<CellId, GroupedHeadId>;
  minWidth?: number;
  sx?: SxProps<Theme> | undefined;
  className?: string;
  isFixedHeader?: boolean;
  showsEmptyTableImage?: boolean;
  showsNoDataFound?: boolean;
  isLoading?: boolean;
  /** 테이블에서 세로 라인이 필요할 때 */
  showsTableVerticalLine?: boolean;
  scrollbarSize?: "default" | "large";
}) {
  const hasToolbarItem = !!toolbarItems || !!csvDownloadInfo;

  const tableContainerRef = useRef<HTMLDivElement>(null);

  const scrollbarRef = useRef<HTMLDivElement>(null);

  const tableRef = useRef<HTMLTableElement>(null);

  const tableHeaderRef = useRef<HTMLTableSectionElement>(null);

  const { target, isActiveTarget } = useIntersectionObserver({
    threshold: 0.05,
  });

  const tableWidth = tableRef.current?.offsetWidth;

  const tableContainerWidth = tableContainerRef.current?.offsetWidth;

  const tableHeaderHeight = tableHeaderRef.current?.offsetHeight;

  const handleScroll: React.EventHandler<React.UIEvent<ReactNode>> =
    useCallback((event: React.UIEvent<React.ReactNode>) => {
      const targetDiv = event.target as HTMLDivElement;

      if (targetDiv === scrollbarRef.current && tableContainerRef.current) {
        tableContainerRef.current.scrollLeft = targetDiv.scrollLeft;
      }
      if (targetDiv === tableContainerRef.current && scrollbarRef.current) {
        scrollbarRef.current.scrollLeft = targetDiv.scrollLeft;
      }
    }, []);

  const StickyScrollbar = useMemo(() => {
    if (!tableWidth) {
      return;
    }
    return (
      <Styled.scrollWrapper
        isPaginationVisible={isActiveTarget}
        minWidth={minWidth}
        ref={scrollbarRef}
        onScroll={handleScroll}
        scrollbarSize={scrollbarSize}
      >
        {!isFixedHeader && <Styled.scroll tableWidth={tableWidth} />}
      </Styled.scrollWrapper>
    );
  }, [
    tableWidth,
    scrollbarSize,
    isActiveTarget,
    minWidth,
    handleScroll,
    isFixedHeader,
  ]);

  const needEditRow = useMemo(() => {
    if (!rows?.length) return false;

    return rows.some((v) => !!v.handleRowAdd || !!v.handleRowRemove);
  }, [rows]);

  /**
   * 리스트 영역을 최대한으로 확보하기위해,
   * UI 요소 포함 여부에따라 최대길이를 동적으로 계산한다
   */
  const tableMaxHeight = useMemo((): string => {
    if (hasToolbarItem) {
      return "calc(70vh)";
    }

    return "calc(80vh)";
  }, [hasToolbarItem]);

  const List = useMemo(() => {
    return (
      <TableContainer
        onScroll={handleScroll}
        ref={tableContainerRef}
        sx={
          scrollbarSize === "large"
            ? {
                maxHeight: tableMaxHeight,
                "&::-webkit-scrollbar": { width: "16px", height: "16px" },
                ...sx,
              }
            : {
                maxHeight: tableMaxHeight,
                ...sx,
              }
        }
      >
        <MUITable
          ref={tableRef}
          stickyHeader
          sx={{
            minWidth: typeof minWidth === "number" ? minWidth : 750,
          }}
          size={"small"}
        >
          <TableHead ref={tableHeaderRef}>
            {groupedHeadRow && (
              <TableRow>
                {groupedHeadRow.map((row, ri) => {
                  if (!row.headCellIds.length) {
                    return null;
                  }

                  return (
                    <TableCell
                      align="center"
                      colSpan={row.headCellIds.length}
                      key={ri}
                      sx={{
                        backgroundColor: "#e9f2ff",
                        borderRight: cellBorderStyle,
                      }}
                    >
                      {row.label}
                    </TableCell>
                  );
                })}
              </TableRow>
            )}

            <TableRow sx={isFixedHeader ? { position: "sticky", top: 0 } : {}}>
              {headCells.map((headCell, index) => {
                const leftStyleValueWhenIsFixed = headCells.reduce(
                  (acc, cur, curIndex) => {
                    if (curIndex < index) {
                      return (acc += cur?.width || 0);
                    }

                    return acc;
                  },
                  0
                );
                return (
                  <TableCell
                    key={headCell.id}
                    padding={headCell.disablePadding ? "none" : "normal"}
                    sx={
                      isFixedHeader
                        ? {
                            width: headCell.width,
                            minWidth: headCell.width,
                            backgroundColor: "#e9f2ff",
                            position: headCell.isFixed ? "sticky" : "static",
                            left: headCell.isFixed
                              ? leftStyleValueWhenIsFixed
                              : 0,
                            top: 0,
                            borderRight: showsTableVerticalLine
                              ? "1px solid #ccc"
                              : undefined,
                          }
                        : {
                            ...(groupedHeadRow ? { top: 37 } : {}),
                            width: headCell.width,
                            minWidth: headCell.width,
                            backgroundColor: "#e9f2ff",
                            borderRight: showsTableVerticalLine
                              ? "1px solid #ccc"
                              : undefined,
                          }
                    }
                  >
                    <Styled.headCell>
                      {headCell.label}

                      {headCell.filter && (
                        <div className="filter">{headCell.filter}</div>
                      )}
                    </Styled.headCell>
                  </TableCell>
                );
              })}

              {needEditRow && (
                <TableCell
                  key="edit-row"
                  sx={{
                    width: "80px",
                    backgroundColor: "#e9f2ff",
                  }}
                />
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {isEmptyObjectOrArray(rows) &&
              showsEmptyTableImage &&
              tableContainerWidth && (
                <TableRow>
                  <TableCell
                    align={"center"}
                    sx={{
                      height: tableMaxHeight,
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        width: tableContainerWidth - 100,
                        left: "20%",
                        top: "80%",
                        padding: 1,
                      }}
                    >
                      <WarningAmberIcon sx={{ fontSize: "50px" }} />
                      <Typography align="center" variant="h5">
                        {isLoading
                          ? "데이터를 로딩 중입니다"
                          : "표시할 데이터가 없습니다"}
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              )}

            {showsNoDataFound && (
              <TableRow>
                <TableCell
                  colSpan={headCells.length}
                  height={"100px"}
                  style={{ background: "#bebebe" }}
                  align={"center"}
                >
                  검색 결과가 없습니다.
                </TableCell>
              </TableRow>
            )}

            <>
              {rows.map((row, index) => {
                const {
                  handleRowClick,
                  handleRowAdd,
                  handleRowRemove,
                  backgroundColor,
                  ...pureRow
                } = row;

                const dictForMaxContentLength = (() => {
                  const dict: { [K in string]: number } = {};

                  headCells.forEach((hc) => {
                    if (hc.maxContentLength) {
                      dict[hc.id] = hc.maxContentLength;
                    }
                  });

                  return dict;
                })();

                return (
                  <TableRow
                    hover
                    onClick={row.handleRowClick}
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                    sx={{
                      cursor: row.handleRowClick ? "pointer" : "default",
                      ...(row.backgroundColor
                        ? { backgroundColor: row.backgroundColor }
                        : {}),
                    }}
                  >
                    {Object.keys(pureRow).map((rowKey, index) => {
                      const isNumeric = !!headCells.find(
                        (hc) => hc.id === rowKey
                      )?.numeric;

                      const verticalAlign = headCells.find(
                        (hc) => hc.id === rowKey
                      )?.verticalAlign;

                      const isFixed = headCells.find(
                        (hc) => hc.id === rowKey
                      )?.isFixed;

                      const leftStyleValueWhenIsFixed = headCells.reduce(
                        (acc, cur, curIndex) => {
                          if (curIndex < index) {
                            return (acc += cur?.width || 0);
                          }

                          return acc;
                        },
                        0
                      );

                      const rowValue = (() => {
                        const val = (pureRow as TableBodyRow<CellId>)[
                          rowKey as CellId
                        ];

                        if (
                          typeof val === "string" &&
                          dictForMaxContentLength[rowKey]
                        ) {
                          // 길이제한이 있는 string에 대해서만 축약해서 표시
                          return omitWithEllipsis({
                            src: val,
                            maxLength: dictForMaxContentLength[rowKey],
                            ellipsis: "...",
                          });
                        }

                        return val;
                      })();

                      return (
                        <TableCell
                          key={rowKey}
                          align={isNumeric ? "right" : "left"}
                          sx={
                            isFixedHeader
                              ? {
                                  position: isFixed ? "sticky" : "static",
                                  left: isFixed ? leftStyleValueWhenIsFixed : 0,
                                  background: isFixed ? blue["50"] : "white",
                                  top: isFixed ? tableHeaderHeight : 0,
                                  verticalAlign: verticalAlign || "middle",
                                  borderRight: showsTableVerticalLine
                                    ? "1px solid #ccc"
                                    : undefined,
                                }
                              : {
                                  verticalAlign: verticalAlign || "middle",
                                  borderRight: showsTableVerticalLine
                                    ? "1px solid #ccc"
                                    : undefined,
                                }
                          }
                        >
                          {rowValue}
                        </TableCell>
                      );
                    })}

                    {needEditRow && (
                      <TableCell key={"edit-row"}>
                        <Styled.editRow>
                          {handleRowRemove && (
                            <div onClick={handleRowRemove}>
                              <RemoveCircleOutline color="error" />
                            </div>
                          )}

                          {handleRowAdd && (
                            <div onClick={handleRowAdd}>
                              <AddCircleOutline color="primary" />
                            </div>
                          )}
                        </Styled.editRow>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </>

            {extraRows}
          </TableBody>
        </MUITable>
      </TableContainer>
    );
  }, [
    handleScroll,
    scrollbarSize,
    tableMaxHeight,
    sx,
    minWidth,
    groupedHeadRow,
    isFixedHeader,
    headCells,
    needEditRow,
    rows,
    showsEmptyTableImage,
    tableContainerWidth,
    isLoading,
    extraRows,
    showsTableVerticalLine,
    tableHeaderHeight,
  ]);

  return (
    <Paper className={className ? `table ${className}` : ""}>
      {title && (
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6">{title}</Typography>
        </Toolbar>
      )}
      {hasToolbarItem && (
        <Styled.toolbarList>
          <div className="left">
            {toolbarItems?.left?.map((ToolBarItem, i) => (
              <Fragment key={i}>{ToolBarItem}</Fragment>
            ))}
          </div>

          <div className="right">
            {toolbarItems?.right?.map((ToolBarItem, i) => (
              <Fragment key={i}>{ToolBarItem}</Fragment>
            ))}

            {csvDownloadInfo && (
              <CSVDownload
                headCells={headCells}
                groupedHeadRow={groupedHeadRow}
                csvDownloadInfo={csvDownloadInfo}
              />
            )}
          </div>
        </Styled.toolbarList>
      )}

      {tableTabInfo && (
        <Tabs
          value={tableTabInfo.activeTabIndex}
          onChange={(e: React.SyntheticEvent, newValue: number) => {
            e.stopPropagation();
            tableTabInfo.setActiveTabIndex(newValue);
          }}
        >
          {tableTabInfo.tabList.map((v, i) => {
            return <Tab key={i} label={v.label} />;
          })}
        </Tabs>
      )}

      {List}

      {pagination && (
        <TablePagination
          ref={target}
          rowsPerPageOptions={pagination.rowsPerPageOptions}
          component="div"
          count={pagination.totalCount || 0}
          rowsPerPage={pagination.perPage}
          page={pagination.currentPage}
          onPageChange={(e, page) => {
            pagination.setCurrentPage(page);
          }}
          showFirstButton
          showLastButton
          onRowsPerPageChange={(e) =>
            pagination.setPerPage(Number(e.target.value) || pagination.perPage)
          }
          labelRowsPerPage={"페이지당 데이터 수"}
        />
      )}

      {StickyScrollbar}
    </Paper>
  );
}
