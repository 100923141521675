import React from "react";

import COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/COMMON_QUERY";
import PARTNER_QUERY from "@sellernote/_shared/src/queries/forwarding/PARTNER_QUERY";
import SHIP_QUERY from "@sellernote/_shared/src/queries/forwarding/SHIP_QUERY";
import TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import {
  FetchTrelloListParams,
  TrelloBidList,
} from "@sellernote/_shared/src/types/forwarding/trello";

import useTrelloDetail from "../../../../../../hooks/useTrelloDetail";

const ShipdaAdminTrelloDetail = ({
  showModal,
  setShowModal,
  bidId,
  trelloListData,
  fetchTrelloListParams,
}: {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  bidId: number | undefined;
  trelloListData?: TrelloBidList[];
  fetchTrelloListParams?: FetchTrelloListParams;
}) => {
  const { data: trelloDetailData, isFetching: isTrelloDetailFetching } =
    TRELLO_BID_QUERY.useGetTrelloBidDetail({
      bidId,
    });

  const { data: shipList } = SHIP_QUERY.useGetShipList();

  const { data: exchangeRateList } = COMMON_QUERY.useGetExchangeRateList({
    all: true,
    countryTarget: trelloDetailData?.locale,
  });

  const { partnerList } = PARTNER_QUERY.useGetPartnerList({
    page: 0,
    enabled: true,
    pageFlag: false,
    region: trelloDetailData?.locale ?? "KR",
  });

  const { TrelloDetailModal } = useTrelloDetail({
    trelloDetailData,
    shipList,
    exchangeRateList,
    partnerList,
    showModal,
    setShowModal,
    isTrelloDetailFetching,
    trelloListData,
    fetchTrelloListParams,
  });

  return TrelloDetailModal;
};

export default ShipdaAdminTrelloDetail;
