import React from "react";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import {
  FetchTrelloListParams,
  TrelloBidList,
} from "@sellernote/_shared/src/types/forwarding/trello";

import ExportTrelloDetail from "./ExportTrelloDetail";
import PartnerAdminTrelloDetail from "./PartnerAdminTrelloDetail";
import ShipdaAdminTrelloDetail from "./ShipdaAdminTrelloDetail";

const TrelloDetail = ({
  showModal,
  setShowModal,
  bidId,
  trelloListData,
  fetchTrelloListParams,
  isExport,
}: {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  bidId: number | undefined;
  trelloListData?: TrelloBidList[];
  fetchTrelloListParams?: FetchTrelloListParams;
  // 수출 의뢰인지 확인
  isExport?: boolean;
}) => {
  if (APP_NAME === "shipda-admin") {
    if (isExport) {
      return (
        <ExportTrelloDetail
          showsExportTrelloDetailModal={showModal}
          setShowsExportTrelloDetailModal={setShowModal}
          bidId={bidId}
          trelloListData={trelloListData}
          fetchTrelloListParams={fetchTrelloListParams}
        />
      );
    }
    return (
      <ShipdaAdminTrelloDetail
        showModal={showModal}
        setShowModal={setShowModal}
        bidId={bidId}
        trelloListData={trelloListData}
        fetchTrelloListParams={fetchTrelloListParams}
      />
    );
  }

  return (
    <PartnerAdminTrelloDetail
      showModal={showModal}
      setShowModal={setShowModal}
      bidId={bidId}
    />
  );
};

export default TrelloDetail;
