import { Dispatch, SetStateAction, useMemo } from "react";

import {
  GET_TRELLO_BID_FINISHED_LIST_RES,
  GET_TRELLO_BID_LIST_RES,
} from "@sellernote/_shared/src/api-interfaces/shipda-api/trello";
import Loading from "@sellernote/_shared/src/componentsToMoveToV1/Loading";
import { ForwardingAdminUserListItem } from "@sellernote/_shared/src/types/forwarding/adminUser";
import { FetchTrelloListParams } from "@sellernote/_shared/src/types/forwarding/trello";

import Layout from "../containers/Layout";

import TrelloList from "../pageContainers/trello/:serviceType/TrelloList";

export default function useTrelloList({
  trelloBidList,
  trelloBidFinishedList,
  adminUserList,
  isAutoFetch,
  setIsAutoFetch,
  fetchTrelloListParams,
  setFetchTrelloListParams,
}: {
  trelloBidList: GET_TRELLO_BID_LIST_RES | undefined;
  trelloBidFinishedList: GET_TRELLO_BID_FINISHED_LIST_RES | undefined;
  adminUserList: ForwardingAdminUserListItem[] | undefined;
  isAutoFetch: boolean;
  setIsAutoFetch: Dispatch<SetStateAction<boolean>>;
  fetchTrelloListParams: FetchTrelloListParams;
  setFetchTrelloListParams: (value: FetchTrelloListParams) => void;
  isFetching?: boolean;
}) {
  const TrelloBidList = useMemo(() => {
    if (!trelloBidFinishedList || !trelloBidList || !adminUserList) {
      return (
        <Layout>
          <Loading active={true} />
        </Layout>
      );
    }

    return (
      <Layout>
        <TrelloList
          fetchTrelloListParams={fetchTrelloListParams}
          setFetchTrelloListParams={setFetchTrelloListParams}
          trelloListData={trelloBidList.concat(trelloBidFinishedList)}
          adminData={adminUserList}
          setIsAutoFetch={setIsAutoFetch}
          isAutoFetch={isAutoFetch}
        />
      </Layout>
    );
  }, [
    adminUserList,
    fetchTrelloListParams,
    isAutoFetch,
    setFetchTrelloListParams,
    setIsAutoFetch,
    trelloBidFinishedList,
    trelloBidList,
  ]);

  return {
    TrelloBidList,
  };
}
