import React from "react";

import COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/COMMON_QUERY";
import CUSTOMS_ADMIN_TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/CUSTOMS_ADMIN_TRELLO_BID_QUERY";

import useTrelloDetail from "../../../../../../hooks/useTrelloDetail";

const PartnerAdminTrelloDetail = ({
  showModal,
  setShowModal,
  bidId,
}: {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  bidId: number | undefined;
}) => {
  const { data: trelloDetailData } =
    CUSTOMS_ADMIN_TRELLO_BID_QUERY.useGetCustomsTrelloBidDetail({
      bidId,
    });

  const { data: exchangeRateList } = COMMON_QUERY.useGetExchangeRateList({
    all: true,
    countryTarget: trelloDetailData?.locale,
  });

  const { TrelloDetailModal } = useTrelloDetail({
    trelloDetailData,
    exchangeRateList,
    showModal,
    setShowModal,
  });

  return TrelloDetailModal;
};

export default PartnerAdminTrelloDetail;
