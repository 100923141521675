import { useCallback, useMemo, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import { useAtomValue } from "jotai";

import ADMIN_FILE_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_FILE_QUERY";
import TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import { TrelloBidDetail } from "@sellernote/_shared/src/types/forwarding/trello";

import UploadModal from "../../../../../../../containers/UploadModal";

import { FORWARDING_ADMIN_AUTH_SELECTORS } from "../../../../../../../jotaiStates/auth";
import RegisterShipImoModal from "../RegisterShipImoModal";

function ShipLetterAndShipImo({
  trelloDetail,
  setShowsErrorSnackBar,
  setShowsSuccessSnackBar,
}: {
  trelloDetail: TrelloBidDetail;
  setShowsErrorSnackBar: (value: boolean) => void;
  setShowsSuccessSnackBar: (value: boolean) => void;
}) {
  const currentAdminAuthInfo = useAtomValue(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_FORWARDING_ADMIN_AUTH_INFO
  );

  const [showsUploadModal, setShowsUploadModal] = useState(false);
  const [showsRegisterShipImoModal, setShowsRegisterShipImoModal] =
    useState(false);

  const { data: attachments, refetch: refetchGetAttachments } =
    TRELLO_BID_QUERY.useGetTrelloAttachments({
      bidId: trelloDetail.id,
    });

  const { mutate: deleteFile } = ADMIN_FILE_QUERY.useDeleteFile({});

  const handleFileDelete = () => {
    if (!shipLetterAttachment) {
      return;
    }

    deleteFile(
      {
        attachmentId: shipLetterAttachment.id,
      },
      {
        onSuccess: () => {
          setShowsSuccessSnackBar(true);
          refetchGetAttachments();
        },

        onError: () => {
          setShowsErrorSnackBar(true);
        },
      }
    );
  };

  const checkIfButtonIsDisabled = useCallback(() => {
    if (currentAdminAuthInfo?.authority === "finance") {
      if (
        trelloDetail.projectStatus === "beforeContactPartner" ||
        trelloDetail.projectStatus === "contactingPartner" ||
        trelloDetail.projectStatus === "scheduling" ||
        trelloDetail.projectStatus === "moving"
      ) {
        return true;
      }
    }

    if (
      trelloDetail.projectStatus === "inSettlement" ||
      trelloDetail.projectStatus === "settlementConfirmed" ||
      trelloDetail.projectStatus === "settlementSecondConfirmed" ||
      trelloDetail.projectStatus === "settlementComplete" ||
      trelloDetail.projectStatus === "portEntryAndPrepareCustoms" ||
      trelloDetail.projectStatus === "payment" ||
      trelloDetail.projectStatus === "completeCustoms" ||
      trelloDetail.projectStatus === "delivering" ||
      trelloDetail.projectStatus === "finished" ||
      trelloDetail.projectStatus === "canceled" ||
      trelloDetail.projectStatus === "failed"
    ) {
      return true;
    }
    return false;
  }, [currentAdminAuthInfo?.authority, trelloDetail.projectStatus]);

  const shipLetterAttachment = useMemo(() => {
    return attachments?.find(
      (attachment) => attachment.domain === "shipLetter"
    );
  }, [attachments]);

  return (
    <Grid container justifyContent={"space-between"}>
      {shipLetterAttachment ? (
        <Grid item container alignItems={"center"} xs={6}>
          <Grid item>
            <Typography variant="subtitle1">공문 : </Typography>
          </Grid>

          <Grid item>
            <Typography variant="body1">{shipLetterAttachment.name}</Typography>
          </Grid>

          <Grid item>
            <Typography
              sx={{ cursor: "pointer", color: red[400] }}
              onClick={() => handleFileDelete()}
              variant="body1"
            >
              {"(X)"}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid item container alignItems={"center"} xs={6} spacing={1}>
          <Grid item>
            <Typography variant="subtitle1">공문 : </Typography>
          </Grid>

          <Grid item>
            <Button
              variant="outlined"
              disabled={
                // 운송 스케줄 변동이 존재하는 지 변경 사유 유무로 확인
                !trelloDetail.management.scheduleChangeReason
              }
              onClick={() => setShowsUploadModal(true)}
            >
              업로드
            </Button>
          </Grid>
        </Grid>
      )}

      {trelloDetail.freightType !== "AIR" && (
        <Grid item>
          <Button
            color="success"
            variant="contained"
            disabled={checkIfButtonIsDisabled()}
            onClick={() => setShowsRegisterShipImoModal(true)}
          >
            모선명 관리
          </Button>
        </Grid>
      )}

      {showsUploadModal && (
        <UploadModal
          setShowUploadModal={setShowsUploadModal}
          showUploadModal={showsUploadModal}
          type={"shipLetter"}
          id={trelloDetail.id}
          dataType={"trello"}
          isPermission={true}
          isMultiple={false}
          acceptedFileTypes={["jpeg", "jpg", "pdf", "png"]}
        />
      )}

      {showsRegisterShipImoModal && (
        <RegisterShipImoModal
          showsRegisterShipImoModal={showsRegisterShipImoModal}
          setShowsRegisterShipImoModal={setShowsRegisterShipImoModal}
        />
      )}
    </Grid>
  );
}

export default ShipLetterAndShipImo;
