import { useCallback, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { Button, Descriptions, Input, message } from "antd";
import * as EmailValidator from "email-validator";
import { useRecoilState } from "recoil";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import ADMIN_BID_QUERY, {
  ADMIN_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_QUERY";
import { FORWARDING_ADMIN_BID_ATOMS } from "@sellernote/_shared/src/states/forwarding/adminBid";
import { AdminBidDetail } from "@sellernote/_shared/src/types/forwarding/adminBid";

import ExporterDescModal from "./ExporterDescModal";
import Styled from "./index.styles";

type InputType = "companyName" | "personName" | "personPhone" | "personEmail";

const ExporterDesc = ({ bidDetail }: { bidDetail: AdminBidDetail }) => {
  const queryClient = useQueryClient();

  const [showsFindExportersModal, setShowsFindExportersModal] = useState(false);

  const [exporterInfo, setExporterInfo] = useRecoilState(
    FORWARDING_ADMIN_BID_ATOMS.ADMIN_BID_EXPORTER_INFO
  );

  const [isEdit, setIsEdit] = useState(false);

  const { mutate: requestExporterInfo } =
    ADMIN_BID_QUERY.useRequestExporterInfo();

  const { mutate: changeShipmentExporterInfo } =
    ADMIN_BID_QUERY.useChangeShipmentExporterInfo();

  const { mutate: changeExportBidDetail } =
    ADMIN_BID_QUERY.useChangeExportBidDetail(bidDetail.id);

  // recoil로 저장된 수출자 정보 초기화
  useEffect(() => {
    return () => {
      setExporterInfo({
        companyName: "",
        personEmail: "",
        personName: "",
        personPhone: "",
      });
    };
  }, [setExporterInfo]);

  const handleExporterInfoRequestClick = useCallback(() => {
    requestExporterInfo(
      {
        pathParams: {
          targetId: bidDetail.id,
          kind: "bid",
        },
      },
      {
        onSuccess: () => {
          message.success("수출입 정보 입력 요청을 했습니다.");
          queryClient.invalidateQueries(
            ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
              bidId: bidDetail.id,
            })
          );
        },
        onError: () => {
          message.error("에러가 있습니다 개발자에게 문의해주세요");
        },
      }
    );
  }, [bidDetail.id, queryClient, requestExporterInfo]);

  const handleExporterInfoUpdateClick = useCallback(() => {
    if (!exporterInfo?.companyName) {
      message.warning("수출자명을 입력해주세요");
      return;
    }
    if (!exporterInfo?.personName) {
      message.warning("담당자 이름을 입력해주세요");
      return;
    }
    if (!exporterInfo?.personPhone) {
      message.warning("수출자 번호를 입력해주세요");
      return;
    }
    if (!exporterInfo?.personEmail) {
      message.warning("이메일을 입력해주세요");
      return;
    }
    if (!EmailValidator.validate(exporterInfo.personEmail)) {
      message.warning("이메일 형식이 아닙니다.");
      return;
    }

    const successResponseHandler = {
      onSuccess: () => {
        message.success("수출입 정보를 수정 했습니다.");
        setIsEdit(false);
        queryClient.invalidateQueries(
          ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
            bidId: bidDetail.id,
          })
        );
      },
    };

    // 콘솔이 아닌 의뢰에선 exporterGroup 값을 주지 않음 1로 고정해서 보내기로 협의함.
    if (bidDetail.isImport) {
      changeShipmentExporterInfo(
        {
          bidId: bidDetail.id,
          exporters: [{ ...exporterInfo, exporterGroup: 1 }],
        },
        {
          ...successResponseHandler,
          onError: ({ response }) => {
            if (response?.data?.code === 404) {
              message.warning("의뢰를 찾을 수 없습니다.");
              return;
            }
            if (response?.data?.code === 400) {
              message.warning("수출자 정보 수정이 불가능한 상태입니다.");
              return;
            }

            message.warning("에러가 있습니다. 개발자에게 문의해주세요.");
            return;
          },
        }
      );
      return;
    }

    changeExportBidDetail(
      {
        exporterInfo,
      },
      {
        ...successResponseHandler,
        onError: () => {
          message.warning("에러가 있습니다. 개발자에게 문의해주세요.");
          return;
        },
      }
    );
    return;
  }, [
    bidDetail.id,
    bidDetail.isImport,
    changeExportBidDetail,
    changeShipmentExporterInfo,
    exporterInfo,
    queryClient,
  ]);

  const handleExporterInfoEditCancelClick = useCallback(() => {
    setExporterInfo({
      companyName: bidDetail?.exporterInfo?.companyName || "",
      personName: bidDetail?.exporterInfo?.personName || "",
      personEmail: bidDetail?.exporterInfo?.personEmail || "",
      personPhone: bidDetail?.exporterInfo?.personPhone || "",
    });
    setIsEdit(false);
  }, [
    setExporterInfo,
    bidDetail?.exporterInfo?.companyName,
    bidDetail?.exporterInfo?.personName,
    bidDetail?.exporterInfo?.personEmail,
    bidDetail?.exporterInfo?.personPhone,
  ]);

  const handleExporterInfoEditOpenClick = useCallback(() => {
    setIsEdit(true);
  }, []);

  const handleExporterInputChange = useCallback(
    (inputValue: string, inputType: InputType) => {
      setExporterInfo({
        ...exporterInfo,
        [inputType]: inputValue,
      });
    },
    [exporterInfo, setExporterInfo]
  );

  return (
    <Styled.exporterInfoContainer>
      <Descriptions
        extra={
          isEdit ? (
            <div>
              <Button
                className="ok-button"
                onClick={() => setShowsFindExportersModal(true)}
                type="primary"
              >
                불러오기
              </Button>

              <Button
                className="ok-button"
                onClick={handleExporterInfoUpdateClick}
                type="primary"
              >
                확인
              </Button>

              <Button
                className="cancel-button"
                onClick={handleExporterInfoEditCancelClick}
                danger
              >
                취소
              </Button>
            </div>
          ) : (
            <div>
              {APP_NAME === "shipda-admin" && bidDetail.isImport && (
                <Button
                  className="push-mail-button"
                  onClick={handleExporterInfoRequestClick}
                  type="primary"
                >
                  PUSH 메일 발송
                </Button>
              )}

              {APP_NAME === "shipda-admin" && (
                <Button
                  className="edit-button"
                  onClick={handleExporterInfoEditOpenClick}
                  type="primary"
                >
                  {bidDetail.isImport ? "수출자 정보 입력" : "수입자 정보 입력"}
                </Button>
              )}
            </div>
          )
        }
        column={4}
        title={
          <div className="title">
            {bidDetail.isImport ? "수출자 정보" : "수입자 정보"}
          </div>
        }
      >
        <Descriptions.Item
          label={bidDetail.isImport ? "수출자명" : "수입자 회사명"}
        >
          {isEdit ? (
            <Input
              className="input"
              value={
                exporterInfo?.companyName ||
                bidDetail?.exporterInfo?.companyName ||
                ""
              }
              onChange={(e) =>
                handleExporterInputChange(e.target.value, "companyName")
              }
            />
          ) : (
            bidDetail?.exporterInfo?.companyName || ""
          )}
        </Descriptions.Item>

        <Descriptions.Item label="담당자 이름">
          {isEdit ? (
            <Input
              className="input"
              value={
                exporterInfo?.personName ||
                bidDetail?.exporterInfo?.personName ||
                ""
              }
              onChange={(e) =>
                handleExporterInputChange(e.target.value, "personName")
              }
            />
          ) : (
            bidDetail?.exporterInfo?.personName || ""
          )}
        </Descriptions.Item>

        <Descriptions.Item label="연락처">
          {isEdit ? (
            <Input
              className="input"
              placeholder="-없이 입력해주세요"
              value={
                exporterInfo?.personPhone ||
                bidDetail?.exporterInfo?.personPhone ||
                ""
              }
              onChange={(e) =>
                handleExporterInputChange(e.target.value, "personPhone")
              }
            />
          ) : (
            bidDetail?.exporterInfo?.personPhone || ""
          )}
        </Descriptions.Item>

        <Descriptions.Item label="이메일" span={1}>
          {isEdit ? (
            <Input
              className="input"
              value={
                exporterInfo?.personEmail ||
                bidDetail?.exporterInfo?.personEmail ||
                ""
              }
              onChange={(e) =>
                handleExporterInputChange(e.target.value, "personEmail")
              }
            />
          ) : (
            bidDetail?.exporterInfo?.personEmail || ""
          )}
        </Descriptions.Item>
      </Descriptions>

      {showsFindExportersModal && (
        <ExporterDescModal
          isImport={bidDetail.isImport}
          teamId={bidDetail.teamId}
          showsFindExportersModal={showsFindExportersModal}
          setShowsFindExportersModal={setShowsFindExportersModal}
        />
      )}
    </Styled.exporterInfoContainer>
  );
};

export default ExporterDesc;
