import COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/COMMON_QUERY";
import PARTNER_QUERY from "@sellernote/_shared/src/queries/forwarding/PARTNER_QUERY";
import SHIP_QUERY from "@sellernote/_shared/src/queries/forwarding/SHIP_QUERY";
import TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import {
  FetchTrelloListParams,
  TrelloBidList,
} from "@sellernote/_shared/src/types/forwarding/trello";

import ExportTrelloDetailModal from "./ExportTrelloDetailModal";

function ExportTrelloDetail({
  showsExportTrelloDetailModal,
  setShowsExportTrelloDetailModal,
  bidId,
  trelloListData,
  fetchTrelloListParams,
}: {
  showsExportTrelloDetailModal: boolean;
  setShowsExportTrelloDetailModal: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  bidId: number | undefined;
  trelloListData?: TrelloBidList[];
  fetchTrelloListParams?: FetchTrelloListParams;
}) {
  const { data: trelloDetailData, isFetching: isTrelloDetailFetching } =
    TRELLO_BID_QUERY.useGetExportTrelloDetail({
      bidId,
    });

  const { data: shipList } = SHIP_QUERY.useGetShipList();

  const { data: exchangeRateList } = COMMON_QUERY.useGetExchangeRateList({
    all: true,
    countryTarget: trelloDetailData?.locale,
  });

  const { partnerList } = PARTNER_QUERY.useGetPartnerList({
    page: 0,
    enabled: true,
    pageFlag: false,
    region: trelloDetailData?.locale ?? "KR",
  });

  return (
    <ExportTrelloDetailModal
      trelloDetailData={trelloDetailData}
      shipList={shipList}
      exchangeRateList={exchangeRateList}
      partnerList={partnerList}
      showsExportTrelloDetailModal={showsExportTrelloDetailModal}
      setShowsExportTrelloDetailModal={setShowsExportTrelloDetailModal}
      isTrelloDetailFetching={isTrelloDetailFetching}
      trelloListData={trelloListData}
      fetchTrelloListParams={fetchTrelloListParams}
    />
  );
}

export default ExportTrelloDetail;
