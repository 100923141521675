import { useState } from "react";
import { useAtomValue } from "jotai";

import useHistoryState from "@sellernote/_shared/src/hooks/common/useHistoryState";
import { FetchTrelloListParams } from "@sellernote/_shared/src/types/forwarding/trello";

import { FORWARDING_ADMIN_AUTH_SELECTORS } from "../jotaiStates/auth";

export default function useTrelloListFetchParams() {
  const currentAdminAuthInfo = useAtomValue(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_FORWARDING_ADMIN_AUTH_INFO
  );

  const [fetchTrelloListParams, setFetchTrelloListParams] =
    useHistoryState<FetchTrelloListParams>("fetchTrelloListParams", {
      region: "all",
      adminId: currentAdminAuthInfo?.id,
      company: undefined,
      bidId: undefined,
      BL: undefined,
    });
  const [isAutoFetch, setIsAutoFetch] = useState(true);

  return {
    fetchTrelloListParams,
    setFetchTrelloListParams,
    isAutoFetch,
    setIsAutoFetch,
  };
}
