import { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useAtomValue } from "jotai";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import {
  FreightType,
  Liner,
} from "@sellernote/_shared/src/types/common/common";
import { BidProjectStatus } from "@sellernote/_shared/src/types/forwarding/bid";
import {
  BLType,
  TrelloBidManagement,
} from "@sellernote/_shared/src/types/forwarding/trello";
import { BL_TYPE_OPTION_LIST } from "@sellernote/_shared/src/utils/common/options";
import { checkIfHaveAuthorityToChangeTrelloDetail } from "@sellernote/_shared/src/utils/forwarding/trello";

import { FORWARDING_ADMIN_AUTH_SELECTORS } from "../../../../../../jotaiStates/auth";
import useMuiSelect from "../../../../../bid/:locale/BidNewTable/useMuiSelect";

//TODO: BL과 선사 리스트 API 수정 후 기능 추가
const UpdateBLNumberAndLiner = ({
  management,
  projectStatus,
  freightType,
  linerData,
}: {
  management: TrelloBidManagement;
  projectStatus: BidProjectStatus;
  freightType: FreightType;
  linerData: Liner[];
}) => {
  const queryClient = useQueryClient();

  const currentAdminAuthInfo = useAtomValue(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_FORWARDING_ADMIN_AUTH_INFO
  );

  const authority = currentAdminAuthInfo?.authority;

  const [isEditMode, setIsEditMode] = useState(false);
  const [linerId, setLinerId] = useState<number | undefined | null>(
    management.linerId
  );
  const [HBL, setHBL] = useState(management.hBL);
  const [MBL, setMBL] = useState(management.mBL);

  const {
    mutate: updateBLNumber,
    ResponseHandler: ResponseHandlerOfUpdateBlNumber,
  } = TRELLO_BID_QUERY.useUpdateBLNumber({
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();
        setIsEditMode(false);
        queryClient.invalidateQueries(TRELLO_BID_QUERY_KEY_GEN.trelloDetail());
      },
      customizeMessage: () => ({
        title: "등록했습니다.",
      }),
    },
    bidId: management.bidId,
  });

  const shipNameList = useMemo(() => {
    return linerData
      .filter((v) => {
        if (freightType === "AIR") {
          return v.type === "air";
        }
        return v.type !== "air";
      })
      .map((v: Liner) => {
        return {
          value: v.id,
          label: v.name,
        };
      });
  }, [freightType, linerData]);

  const selectOptionListOfBLType = useMemo(() => {
    return BL_TYPE_OPTION_LIST.filter((option) => {
      if (freightType === "FCL") {
        return (
          option.label !== "HBL" &&
          option.label !== "MBL" &&
          option.label !== "MAWB/HAWB" &&
          option.label !== "HAWB"
        );
      }

      if (freightType === "LCL") {
        return (
          option.label !== "MBL Direct" &&
          option.label !== "MBL" &&
          option.label !== "MAWB/HAWB" &&
          option.label !== "HAWB"
        );
      }

      return (
        option.label !== "MBL Direct" &&
        option.label !== "MBL" &&
        option.label !== "HBL" &&
        option.label !== "MBL/HBL"
      );
    });
  }, [freightType]);

  const getLinerName = useCallback(
    (linerId: number) => {
      const liner = linerData?.find((linerItem) => {
        return linerItem.id === linerId;
      });

      if (liner) {
        return liner.name;
      }
      return "";
    },
    [linerData]
  );

  const handleMBLInputBlur = useCallback(() => {
    const inputValueOfMBL = MBL;

    if (!inputValueOfMBL) {
      return;
    }

    const linerWithMBLPrefixId = linerData.find((n) => {
      return inputValueOfMBL.includes(n.MBLprefix);
    })?.id;

    if (linerWithMBLPrefixId) {
      setLinerId(linerWithMBLPrefixId);
    }

    return;
  }, [MBL, linerData]);

  const checkIfRequestButtonCanBeRendered = () => {
    if (management.hBL || management.mBL) {
      return isEditMode;
    }
    return true;
  };

  const getInputDisabledValue = () => {
    if (management.hBL || management.mBL) {
      if (isEditMode) {
        return false;
      }
      return true;
    }
    return false;
  };

  const getValueOfHBlRequest = () => {
    if (selectedValueOfBLType === "MBL") {
      return null;
    } else {
      if (!HBL) {
        return null;
      } else {
        return HBL.trim();
      }
    }
  };

  const getValueOfMBlRequest = () => {
    if (selectedValueOfBLType === "HBL") {
      return null;
    } else {
      if (!MBL) {
        return null;
      } else {
        return MBL.trim();
      }
    }
  };

  const { selectedValue: selectedValueOfBLType, MuiSelect: BLTypeSelect } =
    useMuiSelect({
      options: selectOptionListOfBLType,
      title: "BL 타입",
      minWidth: 120,
      defaultValue: management.BLType,
      disabled: getInputDisabledValue(),
    });

  const handleClickUpdateButton = () => {
    return updateBLNumber({
      hBL: getValueOfHBlRequest(),
      mBL: getValueOfMBlRequest(),
      BLType: selectedValueOfBLType as BLType,
      linerId,
    });
  };

  const getRequestButtonDisabled = () => {
    if (selectedValueOfBLType === "ALL") {
      if (freightType === "FCL" || freightType === "LCL") {
        if (!HBL || !linerId || !MBL) {
          return true;
        }
        return false;
      }

      if (!HBL || !MBL) {
        return true;
      }
      return false;
    }

    if (selectedValueOfBLType === "DirectMBL") {
      if (!MBL || !linerId) {
        return true;
      }
      return false;
    }

    if (selectedValueOfBLType === "MBL") {
      if (!MBL) {
        return true;
      }
      return false;
    }

    if (selectedValueOfBLType === "HBL") {
      if (!HBL) {
        return true;
      }
      return false;
    }

    return true;
  };

  const showsLinerSelect =
    shipNameList && (selectedValueOfBLType !== "HBL" || freightType === "AIR");

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={1}>
        <Typography variant="subtitle1" component="span">
          {freightType === "AIR" ? "AWB 입력하기" : "BL 입력하기"}:
        </Typography>
      </Grid>

      <Grid item xs={1.5}>
        {BLTypeSelect}
      </Grid>

      {selectedValueOfBLType === "ALL" && (
        <Grid item xs={2}>
          <TextField
            fullWidth
            label={freightType === "AIR" ? "MAWB" : "MBL"}
            size="small"
            value={MBL}
            disabled={getInputDisabledValue()}
            onBlur={handleMBLInputBlur}
            onChange={(e) => setMBL(e.target.value)}
          />
        </Grid>
      )}

      {selectedValueOfBLType === "ALL" && (
        <Grid item xs={2}>
          <TextField
            fullWidth
            size="small"
            label={freightType === "AIR" ? "HAWB" : "HBL"}
            value={HBL}
            disabled={getInputDisabledValue()}
            onChange={(e) => setHBL(e.target.value)}
          />
        </Grid>
      )}

      {selectedValueOfBLType === "HBL" && (
        <Grid item xs={4}>
          <TextField
            fullWidth
            size="small"
            label={freightType === "AIR" ? "HAWB" : "HBL"}
            value={HBL}
            disabled={getInputDisabledValue()}
            onChange={(e) => setHBL(e.target.value)}
          />
        </Grid>
      )}

      {(selectedValueOfBLType === "DirectMBL" ||
        selectedValueOfBLType === "MBL") && (
        <Grid item xs={4}>
          <TextField
            fullWidth
            label={selectedValueOfBLType}
            size="small"
            value={MBL}
            disabled={getInputDisabledValue()}
            onBlur={handleMBLInputBlur}
            onChange={(e) => setMBL(e.target.value)}
          />
        </Grid>
      )}

      {showsLinerSelect && (
        <Grid item container xs={2.5}>
          <Grid item xs={10}>
            <Autocomplete
              disabled={
                !management.ETD ||
                APP_NAME !== "shipda-admin" ||
                getInputDisabledValue()
              }
              size="small"
              options={shipNameList}
              value={
                linerId
                  ? {
                      value: linerId,
                      label: getLinerName(linerId),
                    }
                  : null
              }
              onChange={(
                event,
                newValue: { value: number | null; label: string } | null
              ) => {
                setLinerId(newValue?.value);
              }}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={freightType === "AIR" ? "항공사명" : "선사명"}
                />
              )}
            />
          </Grid>
        </Grid>
      )}

      {(management.hBL || management.mBL) &&
        !isEditMode &&
        APP_NAME === "shipda-admin" && (
          <Grid item xs={1}>
            <Button
              variant="contained"
              disabled={checkIfHaveAuthorityToChangeTrelloDetail(
                authority,
                projectStatus
              )}
              onClick={() => setIsEditMode(true)}
            >
              수정
            </Button>
          </Grid>
        )}

      {checkIfRequestButtonCanBeRendered() && APP_NAME === "shipda-admin" && (
        <Grid item xs={1}>
          <Button
            variant="contained"
            disabled={
              checkIfHaveAuthorityToChangeTrelloDetail(
                authority,
                projectStatus
              ) || getRequestButtonDisabled()
            }
            onClick={() => {
              handleClickUpdateButton();
            }}
          >
            완료
          </Button>
        </Grid>
      )}

      {ResponseHandlerOfUpdateBlNumber}
    </Grid>
  );
};

export default UpdateBLNumberAndLiner;
