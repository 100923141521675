import { useParams } from "react-router-dom";

import ADMIN_USER_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_USER_QUERY";
import CUSTOMS_ADMIN_TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/CUSTOMS_ADMIN_TRELLO_BID_QUERY";
import { TrelloCategory } from "@sellernote/_shared/src/types/forwarding/trello";
import withRequireAuth from "@sellernote/_shared-for-forwarding-admin/src/hocs/withRequireAuth";
import useTrelloList from "@sellernote/_shared-for-forwarding-admin/src/hooks/useTrelloList";
import useTrelloListFetchParams from "@sellernote/_shared-for-forwarding-admin/src/hooks/useTrelloListFetchParams";

const TrelloListByServiceType = () => {
  const { serviceType } = useParams<TrelloCategory>();

  const {
    fetchTrelloListParams,
    setFetchTrelloListParams,
    isAutoFetch,
    setIsAutoFetch,
  } = useTrelloListFetchParams();

  /**
   * 관세사 어드민 트렐로 카드 요청엔 Region 파라미터 제거
   */
  const { region, ...trelloParams } = fetchTrelloListParams;

  const { data: trelloBidList } =
    CUSTOMS_ADMIN_TRELLO_BID_QUERY.useGetCustomsTrelloBidList(
      {
        ...trelloParams,
        status: "inProgress",
        perPage: 300,
        serviceType:
          serviceType === "consolidation" ? "consolidation" : undefined,
      },
      isAutoFetch
    );

  const { data: trelloBidFinishedList } =
    CUSTOMS_ADMIN_TRELLO_BID_QUERY.useGetCustomsTrelloBidFinishedList(
      {
        ...trelloParams,
        status: "finished",
        projectStatus: "finished",
        perPage: 200,
        serviceType:
          serviceType === "consolidation" ? "consolidation" : undefined,
      },
      isAutoFetch
    );

  const { adminUserList } = ADMIN_USER_QUERY.useGetAdminUserList();

  const { TrelloBidList } = useTrelloList({
    trelloBidList,
    trelloBidFinishedList,
    adminUserList,
    isAutoFetch,
    setIsAutoFetch,
    fetchTrelloListParams,
    setFetchTrelloListParams,
  });

  return TrelloBidList;
};

export default withRequireAuth(TrelloListByServiceType);
