import { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { Skeleton } from "@mui/material";
import { Modal, Spin } from "antd";
import { useSetRecoilState } from "recoil";

import { GET_SHIP_LIST_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/ship";
import { APP_NAME } from "@sellernote/_shared/src/constants";
import { TRELLO_BID_QUERY_KEY_GEN } from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import { FORWARDING_INVOICE_ATOMS } from "@sellernote/_shared/src/states/forwarding/invoice";
import { PartnerListItem } from "@sellernote/_shared/src/types/forwarding/partner";
import {
  ExchangeRate,
  FetchTrelloListParams,
  TrelloBidDetail,
  TrelloBidList,
} from "@sellernote/_shared/src/types/forwarding/trello";
import { useWindowResize } from "@sellernote/_shared/src/utils/common/hook";
import { checkIsSettlement } from "@sellernote/_shared/src/utils/forwarding/trello";

import useCommentList from "../../../../../../hooks/useCommentList";

import BasicInfo from "../BasicInfo";
import RegisterShipSchedule from "../RegisterShipSchedule";
import SalesManagement from "../SalesManagement";
import UploadDocuments from "../UploadDocuments";

function ExportTrelloDetailModal({
  trelloDetailData,
  shipList,
  exchangeRateList,
  partnerList,
  showsExportTrelloDetailModal,
  setShowsExportTrelloDetailModal,
  isTrelloDetailFetching,
  trelloListData,
  fetchTrelloListParams,
}: {
  trelloDetailData: TrelloBidDetail | undefined;
  shipList?: GET_SHIP_LIST_RES | undefined;
  exchangeRateList: ExchangeRate[] | undefined;
  partnerList?: PartnerListItem[];
  showsExportTrelloDetailModal: boolean;
  setShowsExportTrelloDetailModal: Dispatch<SetStateAction<boolean>>;
  isTrelloDetailFetching?: boolean;
  trelloListData?: TrelloBidList[];
  fetchTrelloListParams?: FetchTrelloListParams;
}) {
  const setTrelloCardId = useSetRecoilState(
    FORWARDING_INVOICE_ATOMS.ADMIN_TRELLO_CARD_ID
  );

  const queryClient = useQueryClient();

  const history = useHistory();

  const [windowWidth] = useWindowResize();

  // TODO: 수출 트렐로 API 추가시 분기 추가
  const { CommentList } = useCommentList({
    commentList: trelloDetailData?.management.comments,
    dataId: trelloDetailData?.id,
    dataType: "trello",
  });

  useEffect(() => {
    setTrelloCardId(undefined);
  }, [setTrelloCardId]);

  // 모달의 넓이를 최대한 넓게 해달라는 요청 반영
  const modalWidth = windowWidth - 50;

  const handleCloseModal = useCallback(() => {
    setShowsExportTrelloDetailModal(false);

    const { region, ...trelloParams } = fetchTrelloListParams || {};

    // 종료 카드에서 나갈 때는 API 요청
    if (trelloDetailData?.projectStatus === "finished") {
      queryClient.invalidateQueries(
        TRELLO_BID_QUERY_KEY_GEN.getExportTrelloFinishedList({
          ...trelloParams,
          /** fetchTrelloListParams.region 값이 all 일때는 Query Key 전달 X */
          ...(region === "all" ? [] : { region }),
          status: "finished",
          projectStatus: "finished",
          perPage: 200,
          serviceType:
            trelloDetailData.serviceType === "consolidation"
              ? "consolidation"
              : undefined,
        })
      );
      return;
    }

    if (APP_NAME === "shipda-admin" && trelloListData && trelloDetailData) {
      const oldQueryData = queryClient.getQueryData(
        TRELLO_BID_QUERY_KEY_GEN.getExportTrelloList({
          ...trelloParams,
          /** fetchTrelloListParams.region 값이 all 일때는 Query Key 전달 X */
          ...(region === "all" ? [] : { region }),
          status: "inProgress",
          perPage: 300,
          serviceType:
            trelloDetailData.serviceType === "consolidation"
              ? "consolidation"
              : undefined,
        })
      );

      // 사전에 종료 데이터와 합쳐논 상태이기 때문에 데이터를 분리
      const notFinishedTrelloListData = [...trelloListData].filter((v) => {
        return v.projectStatus !== "finished";
      });

      // 변경한 의뢰번호를 확인해서 인덱스를 찾음
      const targetDataIndex = notFinishedTrelloListData.findIndex((v) => {
        return v.id === trelloDetailData.id;
      });

      // 만약 인덱스를 찾지 못한다면 전체 데이터를 다시 요청(정산 요청을 하게 되는 경우 정산 테이블로 빠지게 되는 경우가 있음) find에서 찾지 못 할 경우 -1를 리턴
      if (targetDataIndex === -1) {
        queryClient.invalidateQueries(TRELLO_BID_QUERY_KEY_GEN.trelloBidList());
        return;
      }

      notFinishedTrelloListData[targetDataIndex] = {
        accountPayables: trelloDetailData.accountPayables,
        attachmentFlag:
          notFinishedTrelloListData[targetDataIndex]?.attachmentFlag,
        attachments: notFinishedTrelloListData[targetDataIndex].attachments,
        containCustoms: trelloDetailData.containCustoms,
        endAddress: trelloDetailData.endAddress,
        endAddressDetail: trelloDetailData.endAddressDetail,
        endCountry: trelloDetailData.endCountry,
        invoices: notFinishedTrelloListData[targetDataIndex].invoices,
        freightType: trelloDetailData.freightType,
        id: trelloDetailData.id,
        incoterms: trelloDetailData.incoterms,
        management: trelloDetailData.management,
        poNumber: notFinishedTrelloListData[targetDataIndex].poNumber,
        projectStatus: trelloDetailData.projectStatus,
        serviceType: trelloDetailData.serviceType,
        startAddress: trelloDetailData.startAddress,
        startCountry: trelloDetailData.startCountry,
        team: trelloDetailData.team,
        region: trelloDetailData.locale,
        isImport: trelloDetailData.isImport,
      };

      // oldQueryData의 타입이 unknown이라서 스프레드 사용 불가 타입체크를 추가
      if (typeof oldQueryData === "object") {
        queryClient.setQueryData(
          TRELLO_BID_QUERY_KEY_GEN.getExportTrelloList({
            ...trelloParams,
            /** fetchTrelloListParams.region 값이 all 일때는 Query Key 전달 X */
            ...(region === "all" ? [] : { region }),
            status: "inProgress",
            perPage: 300,
            serviceType:
              trelloDetailData.serviceType === "consolidation"
                ? "consolidation"
                : undefined,
          }),
          { ...oldQueryData, data: notFinishedTrelloListData }
        );
      }

      return;
    }

    if (checkIsSettlement(trelloDetailData?.projectStatus)) {
      history.push(`/bid/detail/${trelloDetailData?.id}`);
      return;
    }
  }, [
    fetchTrelloListParams,
    history,
    queryClient,
    setShowsExportTrelloDetailModal,
    trelloDetailData,
    trelloListData,
  ]);

  if (!trelloDetailData || !shipList || !exchangeRateList || !partnerList) {
    return (
      <Modal
        destroyOnClose={true}
        style={{ fontFamily: "Roboto" }}
        bodyStyle={{ height: 800, overflow: "scroll" }}
        visible={showsExportTrelloDetailModal}
        width={modalWidth}
        onCancel={handleCloseModal}
        footer={null}
      >
        <Spin />
      </Modal>
    );
  }

  return (
    <Modal
      destroyOnClose={true}
      style={{ fontFamily: "Roboto" }}
      bodyStyle={{ height: 800, overflow: "scroll" }}
      visible={showsExportTrelloDetailModal}
      width={modalWidth}
      onCancel={handleCloseModal}
      footer={null}
    >
      <BasicInfo trelloDetail={trelloDetailData} partnerList={partnerList} />

      {isTrelloDetailFetching ? (
        <Skeleton height={200} />
      ) : (
        <RegisterShipSchedule
          bidId={trelloDetailData.id}
          shipList={shipList.list}
          management={trelloDetailData.management}
          trelloDetail={trelloDetailData}
          region={trelloDetailData.locale}
        />
      )}

      <UploadDocuments trelloDetail={trelloDetailData} />

      <SalesManagement trelloDetailData={trelloDetailData} isExport={true} />

      {CommentList}
    </Modal>
  );
}

export default ExportTrelloDetailModal;
